import React from "react";
import { Dialog, DialogContent, Typography } from "@mui/material";
import useMediaQueries, { MediaQueries } from "hooks/UseMediaQueries";
import { DashboardEntity } from "models/Dashboard";
import DashboardsStore from "stores/DashboardsStore";

import DialogHeader from "components/elements/DialogHeader";
import Toast, { ToastFeedback } from "components/elements/Toast";
import I18n from "components/materials/I18n";

import WidgetToAdd from "../WidgetToAdd";

import * as styles from "./styles";

type IProps = {
	open: boolean;
	close: () => void;
	mediaQueries: MediaQueries;
};
type IState = {
	defaultDashboard: DashboardEntity | null;
	feedback?: ToastFeedback;
};

class Component extends React.Component<IProps, IState> {
	private removeOnChangeDefaultDashboard = () => {};
	private dashboardsStore = DashboardsStore.getInstance();

	public constructor(props: IProps) {
		super(props);
		this.state = {
			defaultDashboard: null,
			feedback: undefined,
		};
	}

	public override render(): React.ReactNode {
		return (
			<>
				<Toast feedback={this.state.feedback} onClose={() => this.setState({ feedback: undefined })} />
				<Dialog maxWidth="xl" open={this.props.open} onClose={this.props.close}>
					<DialogHeader close={this.props.close}>
						<Typography variant="h5">
							<I18n map="components.add_widget_dialog.title" />
						</Typography>
					</DialogHeader>

					<DialogContent sx={styles.widgetsWrapper(this.props.mediaQueries)}>
						{this.state.defaultDashboard?.widgets?.map((widget) => (
							<WidgetToAdd key={widget.id} widget={widget} onClick={this.props.close} />
						))}
					</DialogContent>
				</Dialog>
			</>
		);
	}

	public override componentDidMount(): void {
		this.removeOnChangeDefaultDashboard = this.dashboardsStore.onChangeDefaultDashboard((defaultDashboard) => {
			this.setState({
				defaultDashboard,
			});
		});
	}

	public override componentWillUnmount(): void {
		try {
			this.removeOnChangeDefaultDashboard();
		} catch (err: unknown) {
			this.setState({ feedback: { message: I18n.translate("error_messages.add_widget"), severity: "error" } });
		}
	}
}

export default function DialogAddWidget(props: Omit<IProps, "mediaQueries">) {
	const mediaQueries = useMediaQueries();

	return <Component {...{ ...props, mediaQueries }} />;
}

