import React from "react";
import ReactApexChart from "react-apexcharts";
import { Typography } from "@mui/material";
import { capitalizeFirstLetter } from "helpers/text";
import { Atmobox } from "models/Atmobox";
import { Co2RelativeAverageProps, WidgetEntity } from "models/Widget";

import I18n from "components/materials/I18n";

type IProps = {
	narrowedWidget: WidgetEntity & {
		props: Co2RelativeAverageProps;
	};
};

type IState = {
	options: ApexCharts.ApexOptions | undefined;
	series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
};

export default class CO2RelativeAverageGraph extends React.Component<IProps, IState> {
	private targetAtmobox: Atmobox | null = null;
	private fromDate: Date | null = null;
	private toDate: Date | null = null;
	private atmoboxes: Atmobox[] = [];
	public constructor(props: IProps) {
		super(props);
		this.state = this.calcState();
	}

	public override render() {
		if (!this.atmoboxes.length || !this.targetAtmobox || !this.fromDate || !this.toDate) {
			return (
				<Typography variant="body2">
					<I18n map="general_text.no_data" />
				</Typography>
			);
		}

		return <ReactApexChart options={this.state.options} series={this.state.series} type="boxPlot" height={"300px"} />;
	}

	public override componentDidMount(): void {
		this.setState(this.calcState());
	}

	public override componentDidUpdate(prevProps: Readonly<IProps>): void {
		if (prevProps === this.props) {
			return;
		}

		this.setState(this.calcState());
	}

	private calcState() {
		this.deriveProps();
		const { minimum, quartile25, median, quartile75, maximum } = this.getCo2Statistics();
		return {
			options: {
				chart: {
					width: 720,
					height: 400,
					id: "relative-Co2-average",
					toolbar: {
						show: false,
					},
				},
				stroke: {
					colors: ["#447745"],
				},
				fill: {
					opacity: 0.5,
				},
				xaxis: {
					axisBorder: {
						show: false,
						offsetX: 3,
						offsetY: -1,
					},
					labels: {
						show: false,
					},
					tickAmount: 0,
				},
				yaxis: {
					title: {
						text: "CO2 (ppm)",
						color: "rgba(0, 0, 0, 0.87)",

						offsetX: 0,
						offsetY: 0,
					},
					min: minimum - (maximum - minimum) * 0.2,
					max: maximum + (maximum - minimum) * 0.2,
					tickAmount: 8,
					axisBorder: {
						show: true,
						offsetX: 3,
						offsetY: -1,
					},
					labels: {
						formatter: function (value: number, opts: number) {
							if (opts === 0) {
								return "";
							} else {
								return value === 0 ? "" : Math.round(value).toString();
							}
						},
						style: {
							fontSize: "12px",
							fontFamily: "Inter",
							fontWeight: 500,
							cssClass: "styles.yaxisChart",
						},
					},
				},
				annotations: {
					yaxis: [
						{
							y: (this.targetAtmobox && this.getCo2EntriesAverage(this.getCo2Entries(this.targetAtmobox))) || 0,
							borderColor: "#447745",
							borderWidth: 2,
							strokeDashArray: 0,
							label: {
								textAnchor: "start",
								position: "bottom",
								offsetX: 5,
								offsetY: -10,
								borderColor: "#447745",
								borderWidth: 4,
								style: {
									color: "white",
									background: "#447745",
								},
								text: capitalizeFirstLetter(this.targetAtmobox?.name) ?? "No atmobox",
							},
						},
					],
				},
				plotOptions: {
					bar: {
						columnWidth: "10%",
					},
					boxPlot: {
						colors: {
							upper: "#447745",
							lower: "#447745",
						},
					},
				},
				tooltip: {
					enabled: true,
				},
			},
			series: [
				{
					data: [
						{
							x: "",
							y: [minimum, quartile25, median, quartile75, maximum],
						},
					],
				},
			],
		};
	}

	private deriveProps() {
		this.atmoboxes = this.props.narrowedWidget.data;
		const targetAtmoboxId = this.props.narrowedWidget.props.atmoboxId;
		this.targetAtmobox = this.atmoboxes.find((atmobox) => atmobox.id === targetAtmoboxId) || null;
		this.fromDate = new Date(this.props.narrowedWidget.props.from);
		this.toDate = new Date(this.props.narrowedWidget.props.to);
	}

	private getCo2Entries(atmobox: Atmobox) {
		const co2Entries =
			atmobox.fileEpoches
				?.map((fileEpoch) => fileEpoch.dcFile?.dcEntries?.map((dcEntry) => dcEntry.co2) || [])
				.flat() || [];

		return co2Entries;
	}

	private getCo2EntriesAverage(co2Entries: number[]): number {
		return +(co2Entries.reduce((acc: number, co2Consumption) => co2Consumption + acc, 0) / co2Entries.length).toFixed(
			2,
		);
	}

	private getAllAtmoboxesCo2EntriesAverages() {
		const averages = this.atmoboxes
			.map((atmobox) => this.getCo2EntriesAverage(this.getCo2Entries(atmobox)))
			.filter((average) => !!average);

		return averages;
	}

	private getCo2Statistics(): {
		minimum: number;
		quartile25: number;
		median: number;
		quartile75: number;
		maximum: number;
	} {
		const co2EntriesAverages = this.getAllAtmoboxesCo2EntriesAverages().sort((v1, v2) => v1 - v2);

		const minimum = co2EntriesAverages[0] ?? 0;
		const quartile25 = co2EntriesAverages[Math.floor((co2EntriesAverages.length * 25) / 100)] ?? 0;
		const quartile75 = co2EntriesAverages[Math.floor((co2EntriesAverages.length * 75) / 100)] ?? 0;
		const maximum = co2EntriesAverages[co2EntriesAverages.length - 1] ?? 0;

		const median = co2EntriesAverages[Math.round(co2EntriesAverages.length / 2) - 1] ?? 0;

		return { minimum, quartile25, median, quartile75, maximum };
	}
}
