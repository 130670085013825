import React from "react";
import ReactApexChart from "react-apexcharts";
import { Box, Theme, useTheme } from "@mui/material";
import useMediaQueries, { MediaQueries } from "hooks/UseMediaQueries";
import { SystemEnergyCostProps, WidgetEntity } from "models/Widget";
import DashboardsStore from "stores/DashboardsStore";

import * as styles from "./styles";

type IProps = {
	narrowedWidget: WidgetEntity & {
		props: SystemEnergyCostProps;
	};
	mediaQueries: MediaQueries;
	theme: Theme;
	noInfo?: true;
};
type IState = Record<string, never>;

class Component extends React.Component<IProps, IState> {
	public override render(): React.ReactNode {
		return (
			<Box sx={styles.chart}>
				<ReactApexChart type="radialBar" options={this.getOptions()} series={[this.calcEnergyCost()]} height={170} />
			</Box>
		);
	}

	private getOptions(): ApexCharts.ApexOptions {
		return {
			chart: {
				height: 350,
				type: "radialBar",
				offsetY: -10,
				animations: {
					enabled: true,
					easing: "easeinout",
					speed: 800,
					animateGradually: {
						enabled: true,
						delay: 100,
					},
					dynamicAnimation: {
						enabled: false,
						speed: 0,
					},
				},
			},
			plotOptions: {
				radialBar: {
					startAngle: -135,
					endAngle: 135,
					dataLabels: {
						name: {
							offsetY: 20,
							show: true,
							color: this.props.theme.palette.primary.main,
							fontSize: "14px",
						},
						value: {
							offsetY: -20,
							fontSize: "18px",
							color: this.props.theme.palette.primary.main,
							fontWeight: 700,
							formatter: function (val) {
								return (val / 100).toFixed(1);
							},
						},
					},
				},
			},
			stroke: {
				lineCap: "round",
			},
			labels: ["Wh"],
			fill: {
				colors: [this.props.theme.palette.primary.main],
			},
		};
	}

	public calcEnergyCost() {
		const notarizedDCFilesQuantity = this.props.narrowedWidget.data.reduce((acc, atmobox) => {
			const dcFilesQuantity = atmobox.fileEpoches?.filter((fe) => fe.dcFile?.notarizationStatus !== "none").length || 0;
			return acc + dcFilesQuantity;
		}, 0);

		const systemEnergyCostConstant = DashboardsStore.getInstance().getAtmoboxesConfig()?.systemEnergyCostConstant || 0;

		return notarizedDCFilesQuantity * systemEnergyCostConstant * 100;
	}
}

export default function SystemEnergyCostGraph(props: Omit<IProps, "mediaQueries" | "theme">) {
	const mediaQueries = useMediaQueries();
	const theme = useTheme();

	return <Component {...{ ...props, mediaQueries, theme }} />;
}
