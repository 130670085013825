import { SxProps, Theme } from "@mui/material";

export const headerContent: SxProps<Theme> = {
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
};

export const titleAndInfo: SxProps<Theme> = {
	display: "flex",
	flexDirection: "row",
	alignItems: "flex-start",
};
