import React, { Component } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";

type IProps = {
	setDate: (date: Dayjs | null) => void;
	date: Dayjs;
	noMinutes?: true;
};

type IState = {
	date: Dayjs | undefined;
};

export default class SingleDatePicker extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			date: this.props.date,
		};
	}

	override render() {
		return (
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<Stack direction="row" spacing={3} style={{ minWidth: "100%" }}>
					<MobileDateTimePicker
						minutesStep={this.props.noMinutes ? 60 : 1}
						inputFormat={"DD/MM/YYYY - HH:mm"}
						ampm={false}
						value={this.state.date}
						label="Date"
						onChange={this.handleChange.bind(this)}
						renderInput={(params) => (
							<TextField
								size="medium"
								{...params}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton edge="end">
												<CalendarTodayIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						)}
					/>
				</Stack>
			</LocalizationProvider>
		);
	}

	private handleChange = (newDate: Dayjs | null): void => {
		this.setState({ date: newDate ?? undefined });
		this.props.setDate(newDate);
	};
}

