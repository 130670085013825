import { createTheme } from "@mui/material";
import type {} from "@mui/x-date-pickers/themeAugmentation";

import {
	colorDestructiveDark,
	colorDestructiveMain,
	colorGenericBlack,
	colorGenericWhite,
	colorGrayDark,
	colorGrayLight,
	colorGrayLight2,
	colorGrayMedium,
	colorGreenCEA,
	colorGreenCEADark,
	colorGreenCEALight,
	colorSuccessMain,
	fontSizeMedium,
	spacingLG,
	spacingMD,
	spacingSM,
	spacingXL,
	spacingXS,
	spacingXXL,
} from "./constants";

export const theme = createTheme({
	palette: {
		primary: {
			main: colorGreenCEA,
			light: colorGreenCEALight,
			dark: colorGreenCEADark,
			contrastText: colorGenericWhite,
		},
		success: {
			main: colorSuccessMain,
		},
		error: {
			main: colorDestructiveMain,
			dark: colorDestructiveDark,
		},
		info: {
			light: colorGrayMedium,
			main: colorGrayDark,
			dark: colorGenericBlack,
		},
		background: {
			default: colorGrayLight,
		},
	},
	typography: {
		body1: {
			fontSize: fontSizeMedium,
			color: colorGrayDark,
		},
	},
	spacing: [0, spacingXS, spacingSM, spacingMD, spacingLG, spacingXL, spacingXXL],

	components: {
		MuiButton: {
			styleOverrides: {
				sizeSmall: {
					height: "30px",
				},
				sizeMedium: {
					height: "36px",
				},
				sizeLarge: {
					height: "40px",
				},
				outlinedPrimary: {
					":hover": {
						backgroundColor: colorGreenCEALight,
					},
				},
				textPrimary: {
					":hover": {
						backgroundColor: colorGreenCEALight,
					},
				},
				containedPrimary: {
					"*": {
						color: colorGenericWhite,
					},
					":hover": {
						backgroundColor: colorGreenCEADark,
					},
				},
				containedError: {
					"*": {
						color: colorGenericWhite,
					},
					":hover": {
						backgroundColor: colorDestructiveDark,
					},
				},
			},
		},

		MuiInputBase: {
			styleOverrides: {
				input: {
					height: spacingMD,
				},
			},
		},

		MuiSvgIcon: {
			styleOverrides: {
				fontSizeSmall: {
					fontSize: "16px",
				},
				fontSizeMedium: {
					fontSize: "20px",
				},
			},
		},

		MuiDialogTitle: {
			styleOverrides: {
				root: {
					padding: spacingMD,
					fontWeight: 700,
				},
			},
		},

		MuiDialogContent: {
			styleOverrides: {
				root: {
					padding: spacingMD,
				},
			},
		},

		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: spacingMD,
					":last-child": {
						paddingBottom: spacingMD,
					},
				},
			},
		},

		MuiTypography: {
			styleOverrides: {
				h4: {
					fontWeight: 700,
				},
				h5: {
					fontWeight: 700,
				},
				h6: {
					fontWeight: 700,
				},
			},
		},

		MuiChip: {
			styleOverrides: {
				colorInfo: {
					backgroundColor: colorGrayLight,
					color: colorGrayDark,
					":hover": {
						backgroundColor: colorGrayLight2,
					},
				},
			},
		},
		MuiDateTimePickerToolbar: {
			styleOverrides: {
				timeContainer: {
					Button: {
						marginTop: "14px",
					},
				},
			},
		},
		MuiClockNumber: {
			styleOverrides: {
				root: {
					fontSize: fontSizeMedium,
				},
			},
		},

		MuiDialogActions: {
			styleOverrides: {
				root: {
					padding: spacingMD,
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				arrow: {
					color: colorGenericWhite,
				},
				tooltip: {
					borderColor: colorGenericWhite,
					backgroundColor: colorGenericWhite,
					color: colorGenericBlack,
					maxWidth: "none",
					filter: "drop-shadow(rgba(0, 0, 0, 0.3) 0 2px 10px)",
					fontSize: "14px",
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					color: colorGenericBlack,
					cursor: "pointer",
					textDecorationColor: colorGenericWhite,
				},
			},
		},
	},
});
