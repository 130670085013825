import React from "react";
import ReactApexChart from "react-apexcharts";
import { format } from "date-fns";
import { capitalizeFirstLetter } from "helpers/text";
import useMediaQueries, { MediaQueries } from "hooks/UseMediaQueries";
import { Atmobox, DCEntry, DCFile } from "models/Atmobox";
import { Co2EvolutionProps, WidgetEntity } from "models/Widget";

import I18n from "components/materials/I18n";

type IProps = {
	mediaQueries: MediaQueries;
	narrowedWidget: WidgetEntity & {
		props: Co2EvolutionProps;
	};
};

type IState = {
	options: ApexCharts.ApexOptions | undefined;
	series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
};

class Component extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			series: this.getSeries(),
			options: this.getOptions(),
		};
	}

	public override render() {
		return <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={400} />;
	}

	public override componentDidUpdate(prevProps: Readonly<IProps>): void {
		if (prevProps.narrowedWidget === this.props.narrowedWidget) {
			return;
		}

		this.setState({
			options: this.getOptions(),
			series: this.getSeries(),
		});
	}

	private getSelectedAtmoboxes() {
		const atmoboxes = this.props.narrowedWidget.data;
		const selectedAtmoboxesIds = this.props.narrowedWidget.props.atmoboxesIds;
		return atmoboxes.reduce((acc, atmobox) => {
			if (selectedAtmoboxesIds.includes(atmobox.id)) {
				acc.push(atmobox);
			}
			return acc;
		}, [] as Atmobox[]);
	}

	private getSeries(): ApexAxisChartSeries {
		const allDCEntriesDateTimes = this.getSelectedAtmoboxes()
			.reduce((acc, atmobox) => {
				const fileEpoches = atmobox.fileEpoches;
				if (!fileEpoches) {
					return acc;
				}

				const dcFiles = fileEpoches.filter((fe) => fe.dcFile).map((fe) => fe.dcFile) as DCFile[];

				const dcEntries = dcFiles
					.filter((dcf) => dcf.dcEntries && dcf.dcEntries.length)
					.flatMap((dcf) => dcf.dcEntries)
					.filter((dce) => dce?.date) as DCEntry[];

				dcEntries.forEach((dcEntry) => {
					if (acc.includes(dcEntry.date)) {
						return;
					}

					acc.push(new Date(dcEntry.date));
				});

				return acc;
			}, [] as Date[])
			.sort((d1, d2) => d1.getTime() - d2.getTime());

		const series = this.getSelectedAtmoboxes().map((atmobox: Atmobox) => {
			const data = allDCEntriesDateTimes.map((dateTime) => {
				const dcEntries = atmobox.fileEpoches?.flatMap((fe) => fe.dcFile?.dcEntries?.flatMap((dce) => dce) || []) || [];
				const dcEntryOfDate = dcEntries.find((dce) => new Date(dce.date).getTime() === dateTime.getTime());
				return { y: dcEntryOfDate?.co2 || null, x: dateTime.getTime() };
			});

			return {
				name: atmobox.name,
				data,
			};
		});

		return series;
	}

	private getOptions(): ApexCharts.ApexOptions {
		return {
			stroke: {
				curve: "smooth",
			},
			chart: {
				zoom: {
					autoScaleYaxis: true,
				},
				animations: {
					enabled: false,
				},
				id: `chart-${this.props.narrowedWidget.widgetType.name}-${this.props.narrowedWidget.id}`,
				toolbar: {
					show: false,
				},
			},
			grid: {
				show: true,
				position: "back",
				xaxis: {
					lines: {
						show: true,
						offsetX: 100,
						offsetY: 100,
					},
				},
				yaxis: {
					lines: {
						show: true,
					},
				},
			},
			dataLabels: {
				enabled: false,
			},
			xaxis: {
				type: "datetime",
				tickAmount: this.props.mediaQueries.isMd ? 16 : 8,
				tickPlacement: "on",
				axisTicks: {
					show: true,
					borderType: "solid",
					color: "#78909C",
					height: 500,
					offsetX: 100,
					offsetY: 200,
				},
				axisBorder: {
					show: true,
					offsetX: 0,
					offsetY: -1,
				},
				offsetX: 0,
				offsetY: 0,
				labels: {
					formatter: function (value: string, _, opts): string {
						if (opts.i === 0) {
							return "";
						} else {
							return format(new Date(value), "dd/MM/yy HH:mm");
						}
					},
				},
			},
			yaxis: {
				title: {
					text: "CO2 (ppm)",
					offsetX: 0,
					offsetY: 0,
				},
				tickAmount: 8,
				axisBorder: {
					show: true,
					offsetX: 0,
					offsetY: -1,
				},
				labels: {
					formatter: function (value: number, opts: number) {
						if (opts === 0) {
							return "";
						} else {
							return value === 0 ? "" : Math.round(value).toString();
						}
					},
					style: {
						fontSize: "12px",
						fontFamily: "Inter",
						fontWeight: 500,
						cssClass: "styles.yaxisChart",
					},
				},
			},
			legend: {
				show: true,
				showForSingleSeries: true,
				showForNullSeries: true,
				showForZeroSeries: true,
				position: "bottom",
				horizontalAlign: "left",
				fontSize: "14px",
				fontFamily: "Helvetica, Arial",
				fontWeight: 400,
				offsetX: -30,
				offsetY: 0,
				labels: {
					colors: "white",
				},
				formatter: (atmoName) => capitalizeFirstLetter(atmoName),
				markers: {
					width: 70,
					height: 20,
					radius: 12,
					customHTML: undefined,
					onClick: undefined,
					offsetX: 60,
					offsetY: 6,
				},
				itemMargin: {
					horizontal: -20,
					vertical: 8,
				},
				onItemClick: {
					toggleDataSeries: false,
				},
				onItemHover: {
					highlightDataSeries: true,
				},
			},
			tooltip: {
				enabled: true,
				shared: true,
				followCursor: true,
				custom: undefined,
				inverseOrder: true,
				style: {
					fontSize: "12px",
					fontFamily: undefined,
				},
				x: {
					show: true,
					format: "dd MMM",
				},
				y: {
					formatter: (co2) => (co2 ? co2.toFixed(2) + "ppm" : I18n.translate("general_text.missing_data")),
					title: {
						formatter: (atmoName) => capitalizeFirstLetter(atmoName) + ": ",
					},
				},
				z: {
					formatter: undefined,
					title: "Size: ",
				},
				marker: {
					show: false,
				},
				items: {
					display: "flex",
				},
			},
		};
	}
}

export default function Co2EvolutionGraph(props: Omit<IProps, "mediaQueries">) {
	const mediaQueries = useMediaQueries();

	return <Component {...{ ...props, mediaQueries }} />;
}

