import BaseApiService from "api/BaseApiService";
import Config from "configs/Config";
import { Atmobox, AtmoboxesConfig } from "models/Atmobox";

export default class AtmoboxApi extends BaseApiService {
	private static instance: AtmoboxApi;
	private readonly baseUrl = this.rootUrl.concat(Config.getInstance().get().app).concat("/app-atmoboxes");

	private constructor() {
		super();
	}

	public static getInstance(): AtmoboxApi {
		return (this.instance = this.instance ?? new AtmoboxApi());
	}

	public async getAtmoboxes(): Promise<Atmobox[]> {
		const url = new URL(this.baseUrl);
		try {
			return await this.getRequest<Atmobox[]>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getAtmoboxesConfig(): Promise<AtmoboxesConfig> {
		const url = new URL(`${this.baseUrl}/config`);
		try {
			return await this.getRequest<AtmoboxesConfig>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
