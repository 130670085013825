import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/UseMediaQueries";

export const root: (mediaQueries: MediaQueries) => SxProps<Theme> = (mediaQueries) => (theme) => {
	return {
		...{
			paddingY: theme.spacing(3),
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "space-around",
			minHeight: "124px",
			height: "358px",
			hr: {
				width: "95%",
				borderLeft: "1px solid",
				borderColor: theme.palette.grey[400],
			},
		},
		...(mediaQueries.isLg && {
			paddingY: 0,
			paddingX: theme.spacing(3),
			flexDirection: "row",
			maxHeight: "124px",
			justifyContent: "space-between",
			hr: {
				border: "1px, 0 solid",
				borderColor: theme.palette.grey[400],
				height: "90px",
			},
		}),
	};
};

export const date: (mediaQueries: MediaQueries) => SxProps<Theme> = (mediaQueries) => {
	return {
		...(mediaQueries.isLg && {
			width: "70%",
		}),
	};
};
