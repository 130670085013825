import React from "react";
import { Link } from "react-router-dom";
import { Edit, Menu as MenuIcon } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button, IconButton, Menu, Typography } from "@mui/material";
import ceaLogoIcon from "assets/images/icons/cea-logo-icon.svg";
import ceaLogoText from "assets/images/icons/cea-logo-text.svg";
import useMediaQueries, { MediaQueries } from "hooks/UseMediaQueries";
import { DashboardEntity } from "models/Dashboard";
import SelectedDashboardStore from "stores/SelectedDashboardStore";
import { spacingXS } from "themes/constants";

import Toast, { ToastFeedback } from "components/elements/Toast";

import GoogleLogoutButton from "../GoogleLogoutButton";
import I18n from "../I18n";
import SideMenu from "../SideMenu";

import * as styles from "./styles";

type IProps = {
	mediaQueries: MediaQueries;
};
type IState = {
	selectedDashboard: DashboardEntity | null;
	isEditing: boolean;
	isShowingMenu: boolean;
	feedback?: ToastFeedback;
};

class Component extends React.Component<IProps, IState> {
	private removeOnChangeSelectedDashboard = () => {};
	private removeOnChangeIsEditingDashboard = () => {};
	private readonly selectedDashboardStore = SelectedDashboardStore.getInstance();
	private readonly itemRef = React.createRef<HTMLDivElement>();

	constructor(props: IProps) {
		super(props);
		this.state = {
			selectedDashboard: null,
			isEditing: false,
			isShowingMenu: false,
			feedback: undefined,
		};
		this.turnEditionOn = this.turnEditionOn.bind(this);
		this.resetEdition = this.resetEdition.bind(this);
		this.saveEdition = this.saveEdition.bind(this);
		this.showMenu = this.showMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
	}

	public override render(): React.ReactNode {
		return (
			<>
				<Toast feedback={this.state.feedback} onClose={() => this.setState({ feedback: undefined })} />
				<Box sx={(theme) => ({ backgroundColor: theme.palette.background.default })}>
					<header>
						<Box sx={styles.root(this.props.mediaQueries)}>
							<Box ref={this.itemRef} sx={styles.iconWrapper(this.props.mediaQueries)}>
								<Link to="/">
									<img src={ceaLogoIcon} style={{ marginRight: spacingXS }} />
									<img src={ceaLogoText} />
								</Link>

								{!this.props.mediaQueries.isMd && (
									<>
										<IconButton size="small" onClick={this.showMenu}>
											<MenuIcon fontSize="large" />
										</IconButton>

										{this.state.isShowingMenu && (
											<Menu open onClose={this.closeMenu} anchorEl={this.itemRef.current} sx={styles.menu}>
												{[
													<SideMenu key="header-menu-content" />,
													<Box key="header-menu-logout" sx={{ display: "flex", justifyContent: "center" }}>
														<GoogleLogoutButton />
													</Box>,
												]}
											</Menu>
										)}
									</>
								)}
							</Box>

							<Box sx={styles.mainContent(this.props.mediaQueries)}>
								<Typography
									variant={this.props.mediaQueries.isMd ? "h4" : "h5"}
									visibility={this.state.selectedDashboard ? "initial" : "hidden"}
									noWrap>
									{this.state.selectedDashboard?.name || "Default"}
								</Typography>

								{!this.state.isEditing && !!this.state.selectedDashboard && !this.props.mediaQueries.isMd && (
									<IconButton onClick={this.turnEditionOn} size="small">
										<Edit />
									</IconButton>
								)}
								{!this.state.isEditing && this.props.mediaQueries.isMd && (
									<Box sx={styles.buttons}>
										<Button
											sx={styles.editWidgetsButton(!!this.state.selectedDashboard)}
											variant="contained"
											onClick={this.turnEditionOn}>
											<Typography variant="body2">
												<I18n map="components.dashboard_top_bar.edit_widgets_button" />
											</Typography>
										</Button>
										<GoogleLogoutButton />
									</Box>
								)}

								{this.state.isEditing && this.props.mediaQueries.isMd && (
									<Box sx={styles.widgetsEditionButtons}>
										<Button variant="contained" onClick={this.saveEdition}>
											<Typography variant="body2">
												<I18n map="components.dashboard_top_bar.finish_edition_button" />
											</Typography>
										</Button>
									</Box>
								)}

								{this.state.isEditing && !this.props.mediaQueries.isMd && (
									<Box sx={styles.widgetsEditionButtons}>
										<IconButton onClick={this.saveEdition} size="small">
											<CheckIcon color="primary" />
										</IconButton>
									</Box>
								)}
							</Box>
						</Box>
					</header>
				</Box>
			</>
		);
	}

	public override async componentDidMount() {
		this.removeOnChangeSelectedDashboard = this.selectedDashboardStore.onChangeSelected((selectedDashboard) => {
			this.setState({
				selectedDashboard,
			});
		});

		this.removeOnChangeIsEditingDashboard = this.selectedDashboardStore.onChangeIsEditing((isEditing) => {
			this.setState({
				isEditing,
			});
		});
	}

	public override componentWillUnmount() {
		try {
			this.removeOnChangeSelectedDashboard();
			this.removeOnChangeIsEditingDashboard();
		} catch (err: unknown) {
			this.setState({ feedback: { message: I18n.translate("error_messages.quit_dashboard"), severity: "error" } });
		}
	}

	private turnEditionOn() {
		this.selectedDashboardStore.setIsEditing(true);
	}

	private resetEdition() {
		this.selectedDashboardStore.setIsEditing(false);
	}

	private saveEdition() {
		this.selectedDashboardStore.setIsEditing(false);
	}

	private showMenu() {
		this.setState({ isShowingMenu: true });
	}

	private closeMenu() {
		this.setState({ isShowingMenu: false });
	}
}

export default function DashboardsTopBar() {
	const mediaQueries = useMediaQueries();

	return <Component {...{ mediaQueries }} />;
}

