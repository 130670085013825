import React from "react";
import { Chip, ChipProps } from "@mui/material";

import * as styles from "./styles";

type IProps = ChipProps;
type IState = Record<string, never>;

export default class ChipRightIcon extends React.Component<IProps, IState> {
	public override render(): React.ReactNode {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return <Chip {...this.props} sx={styles.root(this.props.sx)} />;
	}
}
