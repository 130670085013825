import { SxProps, Theme } from "@mui/material";

export const root =
	(incomingSx: SxProps<Theme>) =>
	(theme: Theme): SxProps<Theme> => ({
		position: "relative",
		paddingRight: theme.spacing(3),

		svg: {
			position: "absolute",
			right: theme.spacing(2),
			width: theme.spacing(3),
			marginLeft: 0,
		},
		...incomingSx,
	});
