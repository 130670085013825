import React from "react";
import { Autocomplete, Box, Button, TextField, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { capitalizeFirstLetter } from "helpers/text";
import useMediaQueries, { MediaQueries } from "hooks/UseMediaQueries";
import { Atmobox } from "models/Atmobox";
import { Co2EvolutionProps, WidgetEntity } from "models/Widget";
import DashboardsStore from "stores/DashboardsStore";

import DatePicker from "components/elements/DatePicker";
import Toast, { ToastFeedback } from "components/elements/Toast";
import I18n from "components/materials/I18n";

import EvolutionGraph from "../../content/Co2EvolutionGraph";
import WidgetBottomDetails from "../../materials/WidgetBottomDetails";

import * as styles from "./styles";

type IProps = {
	mediaQueries: MediaQueries;
	narrowedWidget: WidgetEntity & {
		props: Co2EvolutionProps;
	};
	setWidgetProps: (widgetProps: WidgetEntity["props"]) => void;
	previewWidget: (widgetProps: WidgetEntity["props"]) => void;
};
type IState = {
	selectedAtmoboxes: Atmobox[];
	feedback?: ToastFeedback;
};

class Component extends React.Component<IProps, IState> {
	private fromDate: Date;
	private toDate: Date;

	constructor(props: IProps) {
		super(props);

		this.state = {
			selectedAtmoboxes: DashboardsStore.getInstance()
				.getAtmoboxes()
				.filter(({ id }) => this.props.narrowedWidget.props.atmoboxesIds.includes(id)),
			feedback: undefined,
		};

		this.fromDate = this.props.narrowedWidget.props.from;
		this.toDate = this.props.narrowedWidget.props.to;
	}

	public override render(): React.ReactNode {
		return (
			<>
				<Toast feedback={this.state.feedback} onClose={() => this.setState({ feedback: undefined })} />
				<Box sx={styles.header}>
					<Autocomplete
						sx={styles.getPadding}
						multiple
						id={`autocomplete-widget-${this.props.narrowedWidget.widgetType.name}-${this.props.narrowedWidget.id}`}
						options={DashboardsStore.getInstance().getAtmoboxes()}
						onChange={(_, atmoboxes) => this.setSelectedAtmoboxes(atmoboxes)}
						getOptionLabel={(option) => capitalizeFirstLetter(option.name)}
						value={[...this.state.selectedAtmoboxes]}
						filterOptions={this.getFilteredOptions.bind(this)}
						filterSelectedOptions
						renderInput={(params) => (
							<TextField {...params} label={I18n.translate("components.widget.autocomplete.multiple")} />
						)}
						isOptionEqualToValue={(option, value) => option.id === value.id}
					/>
					<Box sx={styles.getPadding}>
						<DatePicker
							setFromDate={this.setFromDate}
							setToDate={this.setToDate}
							fromDate={dayjs(this.props.narrowedWidget.props.from)}
							toDate={dayjs(this.props.narrowedWidget.props.to)}
						/>
					</Box>
					<Button variant="contained" onClick={this.previewWidget.bind(this)}>
						<Typography variant="body2">
							<I18n map="general_text.validate" />
						</Typography>
					</Button>
				</Box>
				<EvolutionGraph narrowedWidget={this.props.narrowedWidget} />
				<WidgetBottomDetails narrowedWidget={this.props.narrowedWidget} />
			</>
		);
	}

	private async previewWidget() {
		const widgetProps = {
			atmoboxesIds: this.state.selectedAtmoboxes.map(({ id }) => id),
			from: this.fromDate,
			to: this.toDate,
		};

		this.props.previewWidget(widgetProps);
	}

	private setSelectedAtmoboxes(atmoboxes: Atmobox[]) {
		this.props.setWidgetProps({
			atmoboxesIds: atmoboxes.map(({ id }) => id),
		} as Co2EvolutionProps);
		this.setState({ selectedAtmoboxes: atmoboxes });
	}

	private setFromDate = (newFromDate: Dayjs | null): void => {
		if (!newFromDate) {
			return;
		}

		this.fromDate = newFromDate.toDate();
		this.props.setWidgetProps({
			from: newFromDate.toDate(),
		} as Co2EvolutionProps);
	};

	private setToDate = (newToDate: Dayjs | null): void => {
		if (!newToDate) {
			return;
		}

		this.toDate = newToDate.toDate();
		this.props.setWidgetProps({
			to: newToDate.toDate(),
		} as Co2EvolutionProps);
	};

	private getFilteredOptions(): Atmobox[] {
		const selectedAtmoboxesId = this.state.selectedAtmoboxes.map((atmobox) => atmobox.id);
		try {
			return DashboardsStore.getInstance()
				.getAtmoboxes()
				.reduce((acc, atmobox) => {
					if (!selectedAtmoboxesId.includes(atmobox.id)) {
						acc.push(atmobox);
					}
					return acc;
				}, [] as Atmobox[]);
		} catch (err: unknown) {
			this.setState({ feedback: { message: I18n.translate("error_messages.preview_widget"), severity: "error" } });
			return [];
		}
	}
}

export default function Co2EvolutionDetails(props: Omit<IProps, "mediaQueries">) {
	const mediaQueries = useMediaQueries();

	return <Component {...{ ...props, mediaQueries }} />;
}
