import React from "react";
import useMediaQueries, { MediaQueries } from "hooks/UseMediaQueries";
import { Co2EvolutionProps, WidgetEntity } from "models/Widget";

import Co2EvolutionGraph from "../../content/Co2EvolutionGraph";

type IProps = {
	mediaQueries: MediaQueries;
	narrowedWidget: WidgetEntity & {
		props: Co2EvolutionProps;
	};
};

type IState = {
	options: ApexCharts.ApexOptions | undefined;
	series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
};

class Component extends React.Component<IProps, IState> {
	public override render() {
		return <Co2EvolutionGraph narrowedWidget={this.props.narrowedWidget} />;
	}
}

export default function Co2EvolutionView(props: Omit<IProps, "mediaQueries">) {
	const mediaQueries = useMediaQueries();

	return <Component {...{ ...props, mediaQueries }} />;
}
