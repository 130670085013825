import React from "react";
import { ThemeProvider } from "@mui/material";
import Config from "configs/Config";
import { gapi } from "gapi-script";
import { theme } from "themes";

import GlobalContext, { defaultContext } from "./GlobalContext";
import MainRoutes from "./MainRoutes";

type IProps = {
	children?: React.ReactNode;
};

export default class Main extends React.Component<IProps> {
	private readonly config = Config.getInstance();

	public constructor(props: IProps) {
		super(props);
		gapi.load("client:auth2", () =>
			gapi.client.init({
				clientId: this.config.get().googleOauth.web.client_id,
				scope: "openid",
			}),
		);
	}

	public override render(): React.ReactNode {
		return (
			<GlobalContext.Provider value={defaultContext}>
				<ThemeProvider theme={theme}>
					<MainRoutes />
				</ThemeProvider>
			</GlobalContext.Provider>
		);
	}
}
