import BaseApiService from "api/BaseApiService";
import { Blob } from "buffer";
import Config from "configs/Config";
import { AtmoboxFile, FileMeta } from "models/Atmobox/index";

export default class FileApi extends BaseApiService {
	private static instance: FileApi;
	private readonly baseUrl = this.rootUrl.concat(Config.getInstance().get().app);
	private readonly endpoints: Record<FileMeta["type"], string> = {
		DriftCorrected: "app-dc-files",
		QC: "app-qc-files",
		QCcal: "app-cal-files",
		raw: "app-raw-files",
	};

	public static getInstance(): FileApi {
		return (this.instance = this.instance ?? new FileApi());
	}

	public async downloadFiles(file: AtmoboxFile, withDependencies: boolean): Promise<Blob> {
		const url = this.getFileEndpoint(file, withDependencies);
		try {
			if (!url) {
				throw new Error("404");
			}
			return await this.getDownload(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	private getFileEndpoint(file: AtmoboxFile, withDependencies: boolean) {
		if (!file.fileMeta) {
			return;
		}
		return new URL(
			this.baseUrl.concat(
				`/${this.endpoints[file.fileMeta.type]}/${file.id}${
					withDependencies ? "?dependencies=true" : "?dependencies=false"
				}`,
			),
		);
	}
}
