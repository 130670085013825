import { SxProps, Theme } from "@mui/material";

export const getPadding: SxProps<Theme> = (theme) => {
	return {
		paddingBottom: theme.spacing(4),
	};
};

export const data: SxProps<Theme> = (theme) => {
	return {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(6),
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
	};
};

export const atmoName: SxProps<Theme> = (theme) => {
	return {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(2),
		fontFamily: "Inter",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "16px",
		lineHeight: "175%",
		letterSpacing: "0.15px",
		color: "rgba(0, 0, 0, 0.87)",
	};
};

export const hr: SxProps<Theme> = () => {
	return {
		hr: {
			borderColor: "rgba(0, 0, 0, 0.1)",
		},
	};
};

