import { useMediaQuery, useTheme } from "@mui/material";

export type MediaQueries = {
	isXs: boolean;
	isSm: boolean;
	isMd: boolean;
	isLg: boolean;
	isXl: boolean;
	is1000: boolean;
};

export default function useMediaQueries(): MediaQueries {
	const theme = useTheme();

	const mediaQueries: MediaQueries = {
		isXs: useMediaQuery(theme.breakpoints.up("xs")),
		isSm: useMediaQuery(theme.breakpoints.up("sm")),
		isMd: useMediaQuery(theme.breakpoints.up("md")),
		isLg: useMediaQuery(theme.breakpoints.up("lg")),
		isXl: useMediaQuery(theme.breakpoints.up("xl")),
		is1000: useMediaQuery(theme.breakpoints.up(1000)),
	};

	return mediaQueries;
}

