import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/UseMediaQueries";

export const graphRow: SxProps<Theme> = () => {
	return {
		height: "100px",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		hr: {
			border: "none",
			borderLeft: "1.5px solid",
			height: "85%",
			borderColor: "rgba(0, 0, 0, 0.2)",
		},
	};
};

export const row: SxProps<Theme> = () => {
	return {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	};
};

export const energyComparison: (mediaQueries: MediaQueries) => SxProps<Theme> = (mediaQueries) => () => {
	return {
		...{
			width: "55%",
			marginLeft: "auto",
			marginRight: "auto",
			color: "rgba(0, 0, 0, 0.6)",
			fontFamily: "Inter",
		},
		...(mediaQueries.isSm && {
			marginRight: "20%",
		}),
	};
};

