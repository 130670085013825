import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton, Tooltip } from "@mui/material";

import I18n from "components/materials/I18n";

import * as styles from "./styles";

export default class SystemEnergyCostToolTip extends React.Component {
	public override render(): React.ReactNode {
		return (
			<Tooltip sx={styles.tooltip} arrow={true} title={I18n.translate("components.widget.energy_cost_info")}>
				<IconButton>
					<InfoOutlinedIcon style={{ color: "black" }} />
				</IconButton>
			</Tooltip>
		);
	}
}
