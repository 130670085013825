import React from "react";

import GlobalContext from "components/GlobalContext";

type IProps = Record<string, never>;
type IState = Record<string, never>;

export default class BasePage<P = IProps, S = IState> extends React.Component<P, S> {
	static override contextType = GlobalContext;
	public override context!: React.ContextType<typeof GlobalContext>;

	public translate(key: string) {
		return this.context.I18nStore.translate(key);
	}
}
