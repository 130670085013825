import React from "react";
import { Close } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";

type IProps = {
	children: React.ReactNode;
	close: () => void;
};
type IState = Record<string, never>;

export default class DialogHeader extends React.Component<IProps, IState> {
	public override render(): React.ReactNode {
		return (
			<Box display="flex" justifyContent="space-between" alignItems="center" padding={3} paddingBottom={2}>
				{this.props.children}

				<IconButton color="info" onClick={() => this.props.close()}>
					<Close fontSize="medium" />
				</IconButton>
			</Box>
		);
	}
}

