import BaseApiService from "api/BaseApiService";
import Config from "configs/Config";
import { EntityId } from "models";
import { WidgetEntity } from "models/Widget";

export default class WidgetApi extends BaseApiService {
	private static instance: WidgetApi;
	private readonly baseUrl = this.rootUrl.concat(Config.getInstance().get().app).concat("/app-widgets");

	private constructor() {
		super();
	}

	public static getInstance(): WidgetApi {
		return (this.instance = this.instance ?? new WidgetApi());
	}

	public async deleteWidget(widgetId: EntityId): Promise<void> {
		const url = new URL(this.baseUrl.concat(`/${widgetId}`));
		try {
			await this.deleteRequest<void>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async updateWidget(widgetId: EntityId, widgetProps: WidgetEntity["props"]): Promise<WidgetEntity> {
		const url = new URL(this.baseUrl.concat(`/${widgetId}`));
		try {
			return await this.patchRequest<WidgetEntity>(url, widgetProps);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async previewWidget(widgetId: EntityId, widgetProps: WidgetEntity["props"]): Promise<WidgetEntity> {
		const url = new URL(this.baseUrl.concat(`/${widgetId}`));
		try {
			return await this.postRequest<WidgetEntity>(url, widgetProps);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
