import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import { theme } from "themes";

type IProps = {
	setSearchQuery: (value: string) => void;
};

type IState = Record<string, never>;

export default class SearchBar extends React.Component<IProps, IState> {
	public override render(): React.ReactNode {
		return (
			<TextField
				id="search-bar"
				onChange={(e) => {
					this.props.setSearchQuery((e.target as HTMLInputElement).value);
				}}
				placeholder="Search File"
				size="small"
				style={{
					width: "300px",
					background: theme.palette.background.default,
					opacity: "10",
					borderRadius: "4px",
					marginBottom: theme.spacing(3),
				}}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon fontSize="medium" />
						</InputAdornment>
					),
				}}
			/>
		);
	}
}

