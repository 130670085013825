import { SxProps, Theme } from "@mui/material";

export const missingData: SxProps<Theme> = (theme) => {
	return {
		marginTop: theme.spacing(4),
		display: "flex",
		flexDirection: "row",
		background: "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #ED6C02",
		borderRadius: "4px",
		height: "52px",
		alignItems: "center",
		justifyContent: "space-between",
	};
};

export const warning: SxProps<Theme> = (theme) => {
	return {
		display: "flex",
		flexDirection: "row",
		gap: theme.spacing(2),
	};
};

