import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/UseMediaQueries";

export const root: (mediaQueries: MediaQueries) => SxProps<Theme> = (mediaQueries) => (theme) => {
	return {
		...{
			paddingY: theme.spacing(3),
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			minHeight: "152px",
			height: "390px",
			justifyContent: "space-around",
			hr: {
				width: "95%",
				borderLeft: "1px solid",
				borderColor: theme.palette.grey[400],
			},
		},
		...(mediaQueries.isLg && {
			paddingY: 0,
			paddingX: theme.spacing(3),
			justifyContent: "space-between",
			flexDirection: "row",
			maxHeight: "152px",
			hr: {
				border: "1px, 0 solid",
				borderColor: theme.palette.grey[400],
				height: "90px",
			},
		}),
	};
};

export const date: (mediaQueries: MediaQueries) => SxProps<Theme> = (mediaQueries) => {
	return {
		...(mediaQueries.isLg && {
			width: "60%",
		}),
	};
};

export const data: SxProps<Theme> = (theme) => {
	return {
		fontFamily: "Inter",
		fontStyle: "normal",
		fontWeight: 700,
		fontSize: "34px",
		lineHeight: "123.5%",
		letterSpacing: "0.25px",
		color: theme.palette.primary.main,
	};
};

export const unit: SxProps<Theme> = (theme) => {
	return {
		paddingLeft: theme.spacing(1),
		paddingTop: theme.spacing(2),
		fontFamily: "Inter",
		fontStyle: "normal",
		fontWeight: 700,
		fontSize: "20px",
		lineHeight: "160%",
		letterSpacing: "0.15px",
		color: theme.palette.primary.main,
	};
};

export const atmoName: SxProps<Theme> = (theme) => {
	return {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(2),
		fontFamily: "Inter",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "16px",
		lineHeight: "175%",
		letterSpacing: "0.15px",
		color: "rgba(0, 0, 0, 0.87)",
	};
};

