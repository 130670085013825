import React from "react";
import { Autocomplete, Box, Button, TextField, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { capitalizeFirstLetter } from "helpers/text";
import useMediaQueries from "hooks/UseMediaQueries";
import { Atmobox } from "models/Atmobox";
import { Co2AverageProps, WidgetEntity } from "models/Widget";
import DashboardsStore from "stores/DashboardsStore";

import DatePicker from "components/elements/DatePicker";
import Toast, { ToastFeedback } from "components/elements/Toast";
import I18n from "components/materials/I18n";

import Co2AverageGraph from "../../content/Co2AverageGraph";
import WidgetBottomDetails from "../../materials/WidgetBottomDetails";

import * as styles from "./styles";

type IProps = {
	narrowedWidget: WidgetEntity & {
		props: Co2AverageProps;
	};
	setWidgetProps: (widgetProps: WidgetEntity["props"]) => void;
	previewWidget: (widgetProps: WidgetEntity["props"]) => void;
};
type IState = {
	selectedAtmobox?: Atmobox;
	feedback?: ToastFeedback;
};

class Component extends React.Component<IProps, IState> {
	private fromDate: Date;
	private toDate: Date;
	private atmoboxName?: string;

	public constructor(props: IProps) {
		super(props);

		const selectedAtmobox = this.props.narrowedWidget.data.find(
			(atmobox) => atmobox.id === this.props.narrowedWidget.props.atmoboxId,
		);
		this.state = {
			selectedAtmobox: selectedAtmobox,
			feedback: undefined,
		};

		this.fromDate = this.props.narrowedWidget.props.from;
		this.toDate = this.props.narrowedWidget.props.to;
		this.atmoboxName = capitalizeFirstLetter(this.state.selectedAtmobox?.name);
	}

	public override render(): React.ReactNode {
		return (
			<>
				<Toast feedback={this.state.feedback} onClose={() => this.setState({ feedback: undefined })} />
				<Autocomplete
					sx={styles.getPadding}
					id={`autocomplete-widget-${this.props.narrowedWidget.widgetType.name}-${this.props.narrowedWidget.id}`}
					onChange={(_, value) => {
						this.setSelectedAtmobox(value);
					}}
					getOptionLabel={(option) => capitalizeFirstLetter(option.name)}
					options={DashboardsStore.getInstance().getAtmoboxes()}
					value={this.state.selectedAtmobox}
					renderInput={(params) => (
						<TextField {...params} label={I18n.translate("components.widget.autocomplete.single")} />
					)}
					isOptionEqualToValue={(option, value) => option.id === value.id}
				/>

				<Box sx={styles.getPadding}>
					<DatePicker
						setFromDate={this.setFromDate}
						setToDate={this.setToDate}
						fromDate={dayjs(this.props.narrowedWidget.props.from)}
						toDate={dayjs(this.props.narrowedWidget.props.to)}
					/>
				</Box>
				<Button variant="contained" onClick={this.previewWidget.bind(this)}>
					<Typography variant="body2">
						<I18n map="general_text.validate" />
					</Typography>
				</Button>
				<Typography sx={styles.atmoName}>{this.atmoboxName}</Typography>
				<Box sx={styles.data}>
					<Co2AverageGraph narrowedWidget={this.props.narrowedWidget} />
				</Box>
				<Box sx={styles.hr}>
					<hr />
				</Box>
				<WidgetBottomDetails narrowedWidget={this.props.narrowedWidget} />
			</>
		);
	}

	public override componentDidUpdate(prevProps: Readonly<IProps>): void {
		if (this.props.narrowedWidget !== prevProps.narrowedWidget) {
			const selectedAtmobox = this.props.narrowedWidget.data.find(
				(atmobox) => atmobox.id === this.props.narrowedWidget.props.atmoboxId,
			);

			if (!selectedAtmobox) {
				return;
			}

			this.setState({
				selectedAtmobox: selectedAtmobox,
			});
			this.fromDate = this.props.narrowedWidget.props.from;
			this.toDate = this.props.narrowedWidget.props.to;
			this.atmoboxName = capitalizeFirstLetter(selectedAtmobox.name);
		}
	}

	private setFromDate = (newFromDate: Dayjs | null): void => {
		if (!newFromDate) {
			return;
		}
		this.fromDate = new Date(newFromDate.toString());
		this.props.setWidgetProps({
			from: this.fromDate,
		} as Co2AverageProps);
	};

	private setToDate = (newToDate: Dayjs | null): void => {
		if (!newToDate) {
			return;
		}
		this.toDate = new Date(newToDate.toString());
		this.props.setWidgetProps({
			to: this.toDate,
		} as Co2AverageProps);
	};

	private async previewWidget() {
		if (!this.state.selectedAtmobox) {
			return;
		}
		const widgetProps = {
			atmoboxId: this.state.selectedAtmobox.id,
			from: this.fromDate,
			to: this.toDate,
		};
		this.props.previewWidget(widgetProps);
	}

	private setSelectedAtmobox(atmobox: Atmobox | null): void {
		if (!atmobox) {
			return;
		}
		this.setState({ selectedAtmobox: atmobox });
		this.props.setWidgetProps({
			atmoboxId: atmobox.id,
		} as Co2AverageProps);
	}
}

export default function Co2AverageDetails(props: Omit<IProps, "mediaQueries">) {
	const mediaQueries = useMediaQueries();

	return <Component {...{ ...props, mediaQueries }} />;
}
