import React from "react";
import { ArrowForward, Cancel } from "@mui/icons-material";
import { Box, Button, Card, CardContent, IconButton, SxProps, Theme, Typography } from "@mui/material";
import useMediaQueries, { MediaQueries } from "hooks/UseMediaQueries";
import {
	AtmoboxesMapProps,
	Co2AverageProps,
	Co2EvolutionProps,
	Co2RelativeAverageProps,
	SystemEnergyCostProps,
	WidgetEntity,
	WidgetType,
} from "models/Widget";
import SelectedDashboardStore from "stores/SelectedDashboardStore";

import Toast, { ToastFeedback } from "components/elements/Toast";
import I18n from "components/materials/I18n";
import DialogDeleteWidget from "components/materials/widgets/materials/DialogDeleteWidget";
import DialogNotarisation from "components/materials/widgets/materials/DialogNotarisation";
import DialogWidgetDetails from "components/materials/widgets/materials/DialogWidgetDetails";
import WidgetHeader from "components/materials/widgets/materials/WidgetHeader";
import Co2EvolutionView from "components/materials/widgets/views/Co2EvolutionView";
import Co2RelativeAverageView from "components/materials/widgets/views/Co2RelativeAverageView";
import SystemEnergyCostView from "components/materials/widgets/views/SystemEnergyCostView";

import AtmoboxesMapView from "../widgets/views/AtmoboxesMapView";
import Co2AverageView from "../widgets/views/Co2AverageView";

import * as styles from "./styles";

type IProps = {
	mediaQueries: MediaQueries;
	widget: WidgetEntity;
	isEditing: boolean;
};
type IState = {
	isViewMore: boolean;
	isDeleting: boolean;
	isNotarisation: boolean;
	feedback?: ToastFeedback;
};

type WidgetComponent =
	| ReturnType<typeof Co2RelativeAverageView>
	| ReturnType<typeof Co2EvolutionView>
	| ReturnType<typeof AtmoboxesMapView>
	| ReturnType<typeof SystemEnergyCostView>
	| ReturnType<typeof Co2AverageView>;

class Component extends React.Component<IProps, IState> {
	private removeOnChangeIsEditing = () => {};
	private selectedDashboardStore = SelectedDashboardStore.getInstance();

	public constructor(props: IProps) {
		super(props);
		this.state = {
			isViewMore: false,
			isDeleting: false,
			isNotarisation: false,
			feedback: undefined,
		};
	}

	public override render() {
		return (
			<Box sx={this.getWidgetStyle()}>
				<Toast feedback={this.state.feedback} onClose={() => this.setState({ feedback: undefined })} />
				{this.state.isViewMore && (
					<DialogWidgetDetails
						open={this.state.isViewMore}
						close={this.closeDetailsDialog.bind(this)}
						widget={this.props.widget}
						withNotarisation={this.isWithNotarisation()}
						showNotarisation={this.showNotarisationDialog.bind(this)}
					/>
				)}
				<DialogDeleteWidget
					open={this.state.isDeleting}
					close={this.closeDeleteDialog.bind(this)}
					onDelete={this.deleteWidget.bind(this)}
				/>
				<DialogNotarisation
					open={this.state.isNotarisation}
					close={this.closeNotarisationDailog.bind(this)}
					widget={this.props.widget}
				/>
				<Card>
					<CardContent>
						<Box sx={styles.header}>
							{this.props.isEditing && (
								<IconButton sx={styles.deleteButton} size="small" onClick={this.showDeleteDialog.bind(this)}>
									<Cancel color="error" />
								</IconButton>
							)}
							<WidgetHeader
								titleVariant="h6"
								widget={this.props.widget}
								showNotarisationDialog={this.showNotarisationDialog.bind(this)}
								withNotarisation={this.isWithNotarisation()}
							/>
							{this.props.mediaQueries.isLg && (
								<Button onClick={this.viewDetailsDialog.bind(this)}>
									<Typography variant="body2" mr={2}>
										<I18n map="components.widget.view_more_button" />
									</Typography>
									<ArrowForward fontSize="small" />
								</Button>
							)}
							{!this.props.mediaQueries.isLg && (
								<IconButton onClick={this.viewDetailsDialog.bind(this)} size="medium">
									<ArrowForward color="primary" fontSize="small" />
								</IconButton>
							)}
						</Box>
						{this.getWidgetComponent(this.props.widget)}
					</CardContent>
				</Card>
			</Box>
		);
	}

	public override componentWillUnmount(): void {
		this.removeOnChangeIsEditing();
	}

	private getWidgetComponent(widget: WidgetEntity): WidgetComponent {
		const components: Record<WidgetType, WidgetComponent> = {
			co2_relative_average: (
				<Co2RelativeAverageView narrowedWidget={{ ...widget, props: widget.props as Co2RelativeAverageProps }} />
			),
			atmoboxes_map: <AtmoboxesMapView narrowedWidget={{ ...widget, props: widget.props as AtmoboxesMapProps }} />,
			co2_average: <Co2AverageView narrowedWidget={{ ...widget, props: widget.props as Co2AverageProps }} />,
			co2_evolution: <Co2EvolutionView narrowedWidget={{ ...widget, props: widget.props as Co2EvolutionProps }} />,
			system_energy_cost: (
				<SystemEnergyCostView narrowedWidget={{ ...widget, props: widget.props as SystemEnergyCostProps }} />
			),
		};

		return components[widget.widgetType.type];
	}

	private viewDetailsDialog() {
		this.setState({
			isViewMore: true,
		});
	}

	private closeDetailsDialog() {
		this.setState({
			isViewMore: false,
		});
	}

	private showDeleteDialog() {
		this.setState({
			isDeleting: true,
		});
	}

	private closeDeleteDialog() {
		this.setState({
			isDeleting: false,
		});
	}

	private showNotarisationDialog() {
		this.setState({
			isNotarisation: true,
		});
	}

	private closeNotarisationDailog() {
		this.setState({
			isNotarisation: false,
		});
	}

	private async deleteWidget() {
		try {
			await this.selectedDashboardStore.deleteWidget(this.props.widget.id);
		} catch (err: unknown) {
			this.setState({ feedback: { message: I18n.translate("error_messages.delete_widget"), severity: "error" } });
		}
	}

	private isWithNotarisation(): boolean {
		const withNotarisation: Record<WidgetType, boolean> = {
			co2_relative_average: false,
			co2_average: true,
			atmoboxes_map: false,
			co2_evolution: true,
			system_energy_cost: false,
		};

		return withNotarisation[this.props.widget.widgetType.type];
	}

	private getWidgetStyle(): SxProps<Theme> {
		const widgetStyles: Record<WidgetType, SxProps<Theme>> = {
			co2_average: styles.smallWidgets(this.props.mediaQueries),
			system_energy_cost: styles.smallWidgets(this.props.mediaQueries),
			co2_relative_average: styles.mediumWidgets(this.props.mediaQueries),
			atmoboxes_map: styles.mediumWidgets(this.props.mediaQueries),
			co2_evolution: styles.largeWidgets(this.props.mediaQueries),
		};

		return widgetStyles[this.props.widget.widgetType.type];
	}
}

export default function Widget(props: Omit<IProps, "mediaQueries">) {
	const mediaQueries = useMediaQueries();

	return <Component {...{ ...props, mediaQueries }} />;
}
