import React from "react";
import { Box, Typography } from "@mui/material";
import { getDCFilesMissingEntries } from "helpers/atmobox";
import { SystemEnergyCostProps, WidgetEntity } from "models/Widget";

import MissingData from "components/elements/MissingData";
import I18n from "components/materials/I18n";

import SystemEnergyCostGraphRow from "../SystemEnergyCostGraphRow";
import SystemEnergyCostToolTip from "../SystemEnergyCostToolTip";

import * as styles from "./styles";

type IProps = {
	narrowedWidget: WidgetEntity & {
		props: SystemEnergyCostProps;
	};
};
type IState = Record<string, never>;

export default class WidgetBottomDetails extends React.Component<IProps, IState> {
	public override render(): React.ReactNode {
		return (
			<>
				<hr />
				<Box sx={styles.titleAndInfo}>
					<Typography>
						<I18n map="components.widget.energy_cost_title" />
					</Typography>
					<SystemEnergyCostToolTip />
				</Box>
				<SystemEnergyCostGraphRow narrowedWidget={this.props.narrowedWidget} />
				<MissingData missingData={getDCFilesMissingEntries(this.props.narrowedWidget.data)} />
			</>
		);
	}
}

