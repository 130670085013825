import React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { MoreHoriz } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { DashboardEntity } from "models/Dashboard";
import SelectedDashboardStore from "stores/SelectedDashboardStore";

import Toast, { ToastFeedback } from "components/elements/Toast";
import I18n from "components/materials/I18n";

import MenuMore from "../MenuMore";
import RenameDashboard from "../RenameDashboard";

import * as styles from "./styles";

type IProps = {
	navigate: NavigateFunction;
	dashboard: DashboardEntity;
};
type IState = {
	isShowingMenu: boolean;
	isShowingRename: boolean;
	isSelectedDashboard: boolean;
	feedback?: ToastFeedback;
};

class Component extends React.Component<IProps, IState> {
	private removeOnChange = () => {};

	private readonly selectedDashboardStore = SelectedDashboardStore.getInstance();
	private readonly itemRef = React.createRef<HTMLButtonElement>();

	public constructor(props: IProps) {
		super(props);
		this.state = {
			isShowingMenu: false,
			isShowingRename: false,
			isSelectedDashboard: false,
			feedback: undefined,
		};

		this.toggleMenu = this.toggleMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
		this.showRename = this.showRename.bind(this);
		this.closeRename = this.closeRename.bind(this);
		this.selectDashboard = this.selectDashboard.bind(this);
	}

	public override render(): React.ReactNode {
		return (
			<>
				<Toast feedback={this.state.feedback} onClose={() => this.setState({ feedback: undefined })} />
				{this.state.isShowingRename && (
					<RenameDashboard dashboard={this.props.dashboard} closeRename={this.closeRename} />
				)}
				{!this.state.isShowingRename && (
					<Button
						ref={this.itemRef}
						sx={styles.root(this.state.isShowingMenu || this.state.isSelectedDashboard)}
						onClick={this.selectDashboard}>
						<Typography variant="body1" noWrap>
							{this.props.dashboard.name}
						</Typography>

						<Box
							sx={styles.icon(this.state.isShowingMenu)}
							onClick={(event) => {
								event.stopPropagation();
								this.toggleMenu();
							}}>
							<MoreHoriz color="info" />
						</Box>

						{this.itemRef.current && this.state.isShowingMenu && (
							<MenuMore
								anchorEl={this.itemRef.current}
								dashboard={this.props.dashboard}
								showRename={this.showRename}
								closeMenu={this.closeMenu}
							/>
						)}
					</Button>
				)}
			</>
		);
	}

	public override componentDidMount() {
		this.removeOnChange = this.selectedDashboardStore.onChangeSelected(() => {
			this.setState({
				isSelectedDashboard: this.selectedDashboardStore.getDashboard()?.id === this.props.dashboard.id,
			});
		});

		this.setState({
			isSelectedDashboard: this.selectedDashboardStore.getDashboard()?.id === this.props.dashboard.id,
		});
	}

	public override componentWillUnmount() {
		try {
			this.removeOnChange();
		} catch (err: unknown) {
			this.setState({ feedback: { message: I18n.translate("error_messages.select_dashboard"), severity: "error" } });
		}
	}
	public toggleMenu() {
		this.setState({ isShowingMenu: !this.state.isShowingMenu });
	}

	public closeMenu() {
		this.setState({ isShowingMenu: false });
	}

	public showRename() {
		this.setState({ isShowingRename: true });
	}

	public closeRename() {
		this.setState({ isShowingRename: false });
		this.closeMenu();
	}

	private selectDashboard() {
		this.props.navigate(`${this.props.dashboard.id}`);
	}
}

export default function DashboardItem(props: Omit<IProps, "navigate">) {
	const navigate = useNavigate();
	return <Component {...{ ...props, navigate }} />;
}

