import React from "react";
import { Box } from "@mui/material";

import Footer from "components/materials/Footer";
import I18n from "components/materials/I18n";

import * as styles from "./styles";

type IProps = {
	title: string;
	children?: React.ReactNode;
	className?: string;
	padding?: boolean;
};

export default class DefaultTemplate extends React.Component<IProps> {
	static defaultProps: IProps = {
		title: "",
		padding: true,
	};

	public override render(): React.ReactNode {
		return (
			<I18n>
				<Box sx={styles.content}>{this.props.children}</Box>
				<footer>
					<Footer />
				</footer>
			</I18n>
		);
	}

	public override componentDidUpdate() {
		this.updateWindowTitle();
	}

	public override componentDidMount() {
		this.updateWindowTitle();
	}

	private updateWindowTitle() {
		window.document.title = this.props.title;
	}
}
