import { SxProps, Theme } from "@mui/material";

export const titleAndInfo: SxProps<Theme> = (theme) => {
	return {
		paddingTop: theme.spacing(5),
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	};
};

