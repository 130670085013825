import React from "react";
import { Add } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import DashboardsStore from "stores/DashboardsStore";

import Toast, { ToastFeedback } from "components/elements/Toast";
import I18n from "components/materials/I18n";

type IProps = Record<string, never>;
type IState = {
	feedback?: ToastFeedback;
};

export default class NewDashboardButton extends React.Component<IProps, IState> {
	private readonly dashboardsStore = DashboardsStore.getInstance();

	public constructor(props: IProps) {
		super(props);
		this.state = {
			feedback: undefined,
		};
		this.handleClick = this.handleClick.bind(this);
	}

	public async handleClick() {
		try {
			await this.dashboardsStore.createDashboard();
		} catch (err: unknown) {
			this.setState({ feedback: { message: I18n.translate("error_messages.create_dashboard"), severity: "error" } });
		}
	}

	public override render(): React.ReactNode {
		return (
			<>
				<Toast feedback={this.state.feedback} onClose={() => this.setState({ feedback: undefined })} />
				<Button onClick={this.handleClick} sx={(theme) => ({ marginBottom: theme.spacing(2), width: "70%" })}>
					<Add fontSize="small" />
					<Typography variant="body2" ml={2}>
						<I18n map="components.side_menu.new_dashboard_button" />
					</Typography>
				</Button>
			</>
		);
	}
}

