import React from "react";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import { Box, Theme, useTheme } from "@mui/material";
import useMediaQueries, { MediaQueries } from "hooks/UseMediaQueries";
import * as Leaflet from "leaflet";
import { Atmobox, Geoloc } from "models/Atmobox";
import { AtmoboxesMapProps, WidgetEntity } from "models/Widget";

import mapMarkerGray from "./mapMarkerGray.svg";
import mapMarkerGraySelected from "./mapMarkerGraySelected.svg";
import mapMarkerGreen from "./mapMarkerGreen.svg";
import mapMarkerGreenSelected from "./mapMarkerGreenSelected.svg";
import mapMarkerOrange from "./mapMarkerOrange.svg";
import mapMarkerOrangeSelected from "./mapMarkerOrangeSelected.svg";
import mapMarkerRed from "./mapMarkerRed.svg";
import mapMarkerRedSelected from "./mapMarkerRedSelected.svg";

export type AtmoboxInfo = {
	atmobox: Atmobox;
	quality: number;
	geoloc?: Geoloc;
};

type IProps = {
	narrowedWidget: WidgetEntity & {
		props: AtmoboxesMapProps;
	};
	setSelectedAtmoboxInfo: (atmoboxInfo: AtmoboxInfo) => void;
	interactive: boolean;
	mediaQueries: MediaQueries;
	theme: Theme;
};
type IState = {
	selectedAtmoboxInfo?: AtmoboxInfo;
};

class Component extends React.Component<IProps, IState> {
	private readonly maxEntriesPerHour = 1800;
	private readonly parisCoordinates: Leaflet.LatLngExpression = [
		48.825 + (48.9 - 48.825) / 2,
		2.276 + (2.4 - 2.276) / 2,
	];

	private grayIcon = new Leaflet.Icon({
		iconUrl: mapMarkerGray,
		iconSize: [14, 20],
		iconAnchor: [7, 20],
	});

	private grayIconSelected = new Leaflet.Icon({
		iconUrl: mapMarkerGraySelected,
		iconSize: [28, 40],
		iconAnchor: [14, 40],
	});

	private redIcon = new Leaflet.Icon({
		iconUrl: mapMarkerRed,
		iconSize: [14, 20],
		iconAnchor: [7, 20],
	});

	private redIconSelected = new Leaflet.Icon({
		iconUrl: mapMarkerRedSelected,
		iconSize: [28, 40],
		iconAnchor: [14, 40],
	});

	private orangeIcon = new Leaflet.Icon({
		iconUrl: mapMarkerOrange,
		iconSize: [14, 20],
		iconAnchor: [7, 20],
	});

	private orangeIconSelected = new Leaflet.Icon({
		iconUrl: mapMarkerOrangeSelected,
		iconSize: [28, 40],
		iconAnchor: [14, 40],
	});

	private greenIcon = new Leaflet.Icon({
		iconUrl: mapMarkerGreen,
		iconSize: [14, 20],
		iconAnchor: [7, 20],
	});

	private greenIconSelected = new Leaflet.Icon({
		iconUrl: mapMarkerGreenSelected,
		iconSize: [28, 40],
		iconAnchor: [14, 40],
	});

	public constructor(props: IProps) {
		super(props);
		this.state = {
			selectedAtmoboxInfo: undefined,
		};
	}

	public override render(): React.ReactNode {
		return (
			<Box mt={this.props.theme.spacing(4)}>
				<MapContainer
					id="map"
					style={{ width: "100%", height: 358 }}
					center={this.parisCoordinates}
					zoom={11}
					scrollWheelZoom={false}>
					<TileLayer
						// attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
						// url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
					/>
					{this.props.narrowedWidget.data.map((atmobox) => {
						const geoloc = this.getGeoloc(atmobox);

						const quality = this.calcQuality(atmobox);
						const markerIcon = this.getMarkerIcon(atmobox, quality);

						return (
							geoloc && (
								<Marker
									key={atmobox.id}
									interactive={this.props.interactive}
									position={[geoloc.lat, geoloc.lon]}
									icon={markerIcon}
									eventHandlers={{
										click: () => {
											this.props.setSelectedAtmoboxInfo({ atmobox, quality, geoloc });
											this.setState({ selectedAtmoboxInfo: { atmobox, quality, geoloc } });
										},
									}}
								/>
							)
						);
					})}
					<FlyMapTo position={this.getCenterCoordinates()} />
				</MapContainer>
			</Box>
		);
	}

	public override componentDidMount(): void {
		const selectedAtmobox = this.props.narrowedWidget.data.find(
			(atmobox) => this.props.narrowedWidget.props.atmoboxId === atmobox.id,
		);

		if (!selectedAtmobox) {
			return;
		}

		const geoloc = this.getGeoloc(selectedAtmobox);

		this.props.setSelectedAtmoboxInfo({
			atmobox: selectedAtmobox,
			quality: this.calcQuality(selectedAtmobox),
			geoloc,
		});

		this.setState({
			selectedAtmoboxInfo: {
				atmobox: selectedAtmobox,
				quality: this.calcQuality(selectedAtmobox),
				geoloc,
			},
		});
	}

	public override componentDidUpdate(prevProps: Readonly<IProps>): void {
		if (prevProps.narrowedWidget.props.atmoboxId === this.props.narrowedWidget.props.atmoboxId) {
			return;
		}

		const selectedAtmobox = this.props.narrowedWidget.data.find(
			(atmobox) => this.props.narrowedWidget.props.atmoboxId === atmobox.id,
		);

		if (!selectedAtmobox) {
			return;
		}

		const geoloc = this.getGeoloc(selectedAtmobox);

		this.props.setSelectedAtmoboxInfo({
			atmobox: selectedAtmobox,
			quality: this.calcQuality(selectedAtmobox),
			geoloc,
		});

		this.setState({
			selectedAtmoboxInfo: {
				atmobox: selectedAtmobox,
				quality: this.calcQuality(selectedAtmobox),
				geoloc,
			},
		});
	}

	private calcQuality(atmobox: Atmobox): number {
		const entriesQuantity = atmobox.fileEpoches?.[0]?.rawFile?.rawEntries?.[0]?.every2SecondsEntriesQuantity || 0;
		const quality = (entriesQuantity * 100) / this.maxEntriesPerHour;
		return quality;
	}

	private getMarkerIcon(atmobox: Atmobox, quality: number) {
		if (atmobox.id === this.state.selectedAtmoboxInfo?.atmobox.id) {
			if (quality === 0) {
				return this.grayIconSelected;
			}
			if (quality <= 50) {
				return this.redIconSelected;
			}
			if (quality <= 95) {
				return this.orangeIconSelected;
			}
			return this.greenIconSelected;
		} else {
			if (quality === 0) {
				return this.grayIcon;
			}
			if (quality <= 50) {
				return this.redIcon;
			}
			if (quality <= 95) {
				return this.orangeIcon;
			}
			return this.greenIcon;
		}
	}

	private getGeoloc(atmobox: Atmobox): Geoloc | undefined {
		// getting fileEpoch with dcEntry matching the date
		const fileEpochHavingDCEntryMatchingDate = atmobox.fileEpoches?.find((fe) =>
			fe.dcFile?.dcEntries?.find(
				(dce) => new Date(dce.date).toISOString() === new Date(this.props.narrowedWidget.props.date).toISOString(),
			),
		);
		return fileEpochHavingDCEntryMatchingDate?.geoloc || undefined;
	}

	private getCenterCoordinates() {
		const selectedAtmobox = this.props.narrowedWidget.data.find(
			(atmobox) => this.state.selectedAtmoboxInfo?.atmobox.id === atmobox.id,
		);

		if (!selectedAtmobox) {
			return this.parisCoordinates;
		}

		const geoloc = this.getGeoloc(selectedAtmobox);

		if (!geoloc) {
			return this.parisCoordinates;
		}
		const centerCoordinates: Leaflet.LatLngTuple = [geoloc.lat, geoloc.lon];
		return centerCoordinates;
	}
}

export default function AtmoboxesMapGraph(props: Omit<IProps, "mediaQueries" | "theme" | "map">) {
	const mediaQueries = useMediaQueries();
	const theme = useTheme();

	return <Component {...{ ...props, mediaQueries, theme }} />;
}

function FlyMapTo({ position }: { position: Leaflet.LatLngExpression }) {
	const map = useMap();

	React.useEffect(() => {
		map.flyTo(position);
	}, [position]);

	return null;
}
