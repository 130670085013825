import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import ceaLogoIcon from "assets/images/icons/cea-logo-icon.svg";
import ceaLogoText from "assets/images/icons/cea-logo-text.svg";
import { UserEntity } from "models/AuthFactory/user";
import UserStore from "stores/UserStore";
import { spacingXS } from "themes/constants";

import LaunchAppButton from "../../elements/LaunchAppButton";
import GoogleLoginButton from "../GoogleLoginButton";

import * as styles from "./styles";

type IProps = Record<string, never>;
type IState = {
	user: UserEntity | null;
};

export default class HomeTopBar extends React.Component<IProps, IState> {
	private removeOnChange = () => {};
	private readonly userStore = UserStore.getInstance();

	constructor(props: IProps) {
		super(props);
		this.state = {
			user: this.userStore.getUser(),
		};
	}

	public override render(): React.ReactNode {
		return (
			<Box sx={(theme) => ({ backgroundColor: theme.palette.background.default })}>
				<header>
					<Box sx={styles.root}>
						<Box>
							<Link to="/">
								<img src={ceaLogoIcon} style={{ marginRight: spacingXS }} />
								<img src={ceaLogoText} />
							</Link>
						</Box>

						<Box>
							{!this.state.user && <GoogleLoginButton isOnLandingPage={false} />}
							{this.state.user && <LaunchAppButton />}
						</Box>
					</Box>
				</header>
			</Box>
		);
	}

	public override componentDidMount() {
		this.removeOnChange = this.userStore.onChange((user) => {
			this.setState({
				user,
			});
		});
	}

	public override componentWillUnmount() {
		this.removeOnChange();
	}
}
