import DashboardApi from "api/Dashboard";
import WidgetApi from "api/WidgetApi";
import { EntityId } from "models";
import { DashboardEntity } from "models/Dashboard";
import { WidgetEntity } from "models/Widget";
import EventService from "services/EventEmitter";

export default class SelectedDashboardStore {
	private static instance: SelectedDashboardStore;
	private readonly event = new EventService();
	private readonly dashboardApi = DashboardApi.getInstance();
	private readonly widgetApi = WidgetApi.getInstance();

	private dashboardId: EntityId | null = null;
	private dashboard: DashboardEntity | null = null;
	private isEditing = false;

	private constructor() {}

	public static getInstance(): SelectedDashboardStore {
		return (this.instance = this.instance ?? new SelectedDashboardStore());
	}

	private updateDashboard(updatedWidget: WidgetEntity) {
		if (!this.dashboard) {
			throw new Error("Dashboard is undefined");
		}
		const widgets = this.dashboard.widgets || [updatedWidget];

		const widgetIndex = widgets.findIndex(({ id }) => updatedWidget.id === id);
		if (widgetIndex === -1) {
			throw new Error("Widget not exist");
		}

		widgets[widgetIndex] = updatedWidget;
		this.dashboard.widgets = widgets;

		this.setDashboard(this.dashboard);
	}

	public async fetchDashboard() {
		const dashboard = this.dashboardId ? await this.dashboardApi.getDashboard(this.dashboardId) : null;
		this.setDashboard(dashboard);
	}

	public async addWidget(widgetId: EntityId) {
		if (!this.dashboardId) {
			throw new Error("dashboardId is null");
		}
		const updatedDashboard = await this.dashboardApi.addWidget(this.dashboardId, widgetId);
		this.setDashboard(updatedDashboard);
	}

	public async deleteWidget(widgetId: EntityId) {
		if (!this.dashboardId) {
			throw new Error("dashboardId is null");
		}
		await this.widgetApi.deleteWidget(widgetId);
		await this.fetchDashboard();
	}

	public async updateWidget(widgetId: EntityId, widgetProps: WidgetEntity["props"]) {
		if (!this.dashboardId) {
			throw new Error("dashboardId is null");
		}
		const updatedWidget = await this.widgetApi.updateWidget(widgetId, widgetProps);
		this.updateDashboard(updatedWidget);
	}

	public getDashboard() {
		return this.dashboard;
	}

	public async setDashboardId(dashboardId: EntityId | null) {
		this.dashboardId = dashboardId;
		await this.fetchDashboard();
	}

	private setDashboard(dashboard: DashboardEntity | null) {
		this.dashboardId = dashboard?.id || null;
		this.dashboard = dashboard;
		this.event.emit("changeSelected", this.dashboard);
	}

	public getIsEditing() {
		return this.isEditing;
	}

	public setIsEditing(isEditing: boolean) {
		this.isEditing = isEditing;
		this.event.emit("changeIsEditing", this.isEditing);
	}

	public onChangeSelected(callback: (selectedDashboard: DashboardEntity) => void) {
		this.event.on("changeSelected", callback);
		return () => {
			this.event.off("changeSelected", callback);
		};
	}

	public onChangeIsEditing(callback: (isEditing: boolean) => void) {
		this.event.on("changeIsEditing", callback);
		return () => {
			this.event.off("changeIsEditing", callback);
		};
	}

	public disconnect() {
		this.setDashboard(null);
	}
}
