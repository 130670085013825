import AuthApi from "api/Auth";
import { UserEntity } from "models/AuthFactory/user";
import EventService from "services/EventEmitter";

import JwtStore from "./JwtStore";

export default class UserStore {
	private static instance: UserStore;
	private readonly event = new EventService();
	private readonly jwtStore = JwtStore.getInstance();
	private readonly authApi = AuthApi.getInstance();

	private user: UserEntity | null = null;

	private constructor() {}

	public static getInstance(): UserStore {
		return (this.instance = this.instance ?? new UserStore());
	}

	public getUser() {
		return this.user;
	}

	public setUser(user: UserEntity | null) {
		this.user = user;
		this.event.emit("change", user);
	}

	public async signInOAuth(oAuthToken: string) {
		const { jwtPair } = await this.authApi.signInWithOAuth({ oAuthToken });
		this.jwtStore.setJwtPair(jwtPair);
	}

	public async signOut() {
		try {
			const { jwtPair } = await this.authApi.signOut();
			this.jwtStore.setJwtPair(jwtPair);
		} catch (err) {
			console.error(err);
		}
	}

	public onChange(callback: (user: UserEntity | null) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public disconnect() {
		this.setUser(null);
	}
}
