import { SxProps, Theme } from "@mui/material";

export const getPadding: SxProps<Theme> = (theme) => {
	return {
		...{
			paddingBottom: theme.spacing(4),
		},
	};
};

