import { SxProps, Theme } from "@mui/material";

export const content: SxProps<Theme> = {
	display: "flex",
	flexDirection: "column",
	width: "157px",
};

export const root: SxProps<Theme> = {
	marginLeft: "70px",
	marginTop: "-7px",
};

export const button: SxProps<Theme> = {
	display: "flex",
	textTransform: "none",
	width: "100%",
	justifyContent: "flex-start",
};

export const icon: SxProps<Theme> = (theme) => ({
	margin: theme.spacing(0, 5, 0, 2),
});
