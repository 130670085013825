import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";

import I18n from "components/materials/I18n";

type IProps = Record<string, never>;
type IState = Record<string, never>;

export default class LaunchAppButton extends React.Component<IProps, IState> {
	public override render(): React.ReactNode {
		return (
			<Link to="/dashboards">
				<Button variant="contained">
					<Typography variant="body2">
						<I18n map="components.home_top_bar.launch_app_button" />
					</Typography>
				</Button>
			</Link>
		);
	}
}
