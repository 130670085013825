import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/UseMediaQueries";

export const root: SxProps<Theme> = {
	width: "100%",
	maxWidth: "false",
};

export const detailsOfCO2RelativeAverage: (mediaQueries: MediaQueries) => SxProps<Theme> = (mediaQueries) => () => {
	return {
		...{
			width: "350px",
		},
		...(mediaQueries.isSm && {
			width: "700px",
		}),
	};
};

export const detailsOfCO2Evolution: (mediaQueries: MediaQueries) => SxProps<Theme> = (mediaQueries) => () => {
	return {
		...{
			width: "350px",
		},
		...(mediaQueries.isSm && {
			width: "700px",
		}),
		...(mediaQueries.isMd && {
			width: "900px",
		}),
		...(mediaQueries.isLg && {
			width: "1200px",
		}),
	};
};

