import { SxProps, Theme } from "@mui/material";
// import { MediaQueries } from "Hooks/UseMediaQueries";

export const atmoName: SxProps<Theme> = (theme) => {
	return {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(2),
		fontFamily: "Inter",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "16px",
		lineHeight: "175%",
		letterSpacing: "0.15px",
		color: "rgba(0, 0, 0, 0.87)",
	};
};

