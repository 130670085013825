import AuthApi from "api/Auth";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { JwtPair } from "models/AuthFactory/accessToken";
import JwtStore from "stores/JwtStore";
import UserStore from "stores/UserStore";

export default class StoreWorflow {
	private static instance: StoreWorflow;
	private readonly authApi = AuthApi.getInstance();
	private readonly jwtStore = JwtStore.getInstance();
	private readonly userStore = UserStore.getInstance();

	private constructor() {
		this.initEvents();
		this.setUser();
	}

	public static getInstance(): StoreWorflow {
		return (this.instance = this.instance ?? new StoreWorflow());
	}

	private initEvents() {
		this.onJwtPairChange();
	}

	private setUser() {
		const accessToken = this.jwtStore.getAccessToken();
		const refreshToken = this.jwtStore.getRefreshToken();
		if (accessToken && refreshToken) {
			this.authApi.signIn().then((res) => {
				this.jwtStore.setJwtPair(res.jwtPair);
			});
		} else {
			this.authApi.presign().then((res) => {
				this.jwtStore.setJwtPair(res.jwtPair);
			});
		}
	}

	private onJwtPairChange() {
		this.jwtStore.onChange((jwtPair?: JwtPair) => {
			if (!jwtPair) {
				this.userStore.setUser(null);
				return;
			}

			const decodedToken = jwtDecode<JwtPayload>(jwtPair.accessToken);
			if (!decodedToken.sub) {
				this.userStore.setUser(null);
				return;
			}

			this.userStore.setUser({ id: +decodedToken.sub });
		});
	}

	public disconnect() {
		this.userStore.disconnect();
	}
}
