function download(blob: Blob, filename: string) {
	// Create blob link to download
	const url = window.URL.createObjectURL(new Blob([blob as BlobPart]));
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", filename);

	// Append to html page
	document.body.appendChild(link);

	// Force download
	link.click();

	// Clean up and remove the link
	link.parentNode?.removeChild(link);
}

const FileDownloader = {
	download,
};

export default FileDownloader;
