import { SxProps, Theme } from "@mui/material";

export const root: SxProps<Theme> = {
	position: "relative",
	height: "366px",
};

export const atmoboxInfo: SxProps<Theme> = (theme) => {
	return {
		position: "absolute",
		display: "flex",
		left: theme.spacing(3),
		bottom: theme.spacing(4),
		zIndex: 1000,
		px: theme.spacing(2),
		py: theme.spacing(1),
	};
};

export const atmoboxName: SxProps<Theme> = {
	fontWeight: "bold",
};

