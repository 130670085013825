import React from "react";
import { Box } from "@mui/material";

import NewDashboardButton from "components/materials/NewDashboardButton";

import DashboardList from "./DashboardList";
import * as styles from "./styles";

type IProps = {
	key: string;
};

type IState = Record<string, never>;

export default class SideMenu extends React.Component<IProps, IState> {
	public override render(): React.ReactNode {
		return (
			<Box sx={styles.root}>
				<NewDashboardButton />
				<DashboardList />
			</Box>
		);
	}
}

