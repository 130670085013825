import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Autocomplete, Box, Button, Chip, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import WidgetApi from "api/WidgetApi";
import dayjs, { Dayjs } from "dayjs";
import { getDCFilesMissingEntries } from "helpers/atmobox";
import { capitalizeFirstLetter } from "helpers/text";
import useMediaQueries, { MediaQueries } from "hooks/UseMediaQueries";
import { Atmobox, Geoloc } from "models/Atmobox";
import { AtmoboxesMapProps, WidgetEntity } from "models/Widget";
import DashboardsStore from "stores/DashboardsStore";
import { theme } from "themes";

import MissingData from "components/elements/MissingData";
import SingleDatePicker from "components/elements/SingleDatePicker";
import Toast, { ToastFeedback } from "components/elements/Toast";
import I18n from "components/materials/I18n";

import AtmoboxesMapGraph, { AtmoboxInfo } from "../../content/AtmoboxesMapGraph";

import * as styles from "./styles";

type IProps = {
	mediaQueries: MediaQueries;
	narrowedWidget: WidgetEntity & {
		props: AtmoboxesMapProps;
	};
	setWidgetProps: (widgetProps: WidgetEntity["props"]) => void;
};

type IState = {
	widgetPreview: WidgetEntity & {
		props: AtmoboxesMapProps;
	};
	selectedAtmobox?: Atmobox;
	quality?: number;
	geoloc?: Geoloc;
	feedback?: ToastFeedback;
};

class Component extends React.Component<IProps, IState> {
	private date: Date;
	private atmoboxName?: string;

	constructor(props: IProps) {
		super(props);

		const selectedAtmobox = this.props.narrowedWidget.data.find(
			(atmobox) => atmobox.id === this.props.narrowedWidget.props.atmoboxId,
		);
		this.state = {
			widgetPreview: this.props.narrowedWidget,
			selectedAtmobox: selectedAtmobox,
			quality: undefined,
			feedback: undefined,
			geoloc: undefined,
		};

		this.date = this.state.widgetPreview.props.date;
		this.atmoboxName = capitalizeFirstLetter(this.state.selectedAtmobox?.name ?? "No atmobox");
	}

	public override render(): React.ReactNode {
		return (
			<>
				<Toast feedback={this.state.feedback} onClose={() => this.setState({ feedback: undefined })} />
				<Box sx={styles.header}>
					<Autocomplete
						sx={styles.getPadding}
						id={`autocomplete-widget-${this.state.widgetPreview.widgetType.name}-${this.state.widgetPreview.id}`}
						onChange={(_, value) => {
							this.setSelectedAtmoboxFromSelect(value);
						}}
						getOptionLabel={(option) => capitalizeFirstLetter(option.name)}
						options={DashboardsStore.getInstance().getAtmoboxes()}
						value={this.state.selectedAtmobox}
						renderInput={(params) => (
							<TextField {...params} label={I18n.translate("components.widget.autocomplete.single")} />
						)}
						isOptionEqualToValue={(option, value) => option.id === value.id}
					/>
					<Box sx={styles.getPadding}>
						<SingleDatePicker
							noMinutes
							setDate={this.setDate.bind(this)}
							date={dayjs(this.state.widgetPreview.props.date)}
						/>
					</Box>
					<Button variant="contained" onClick={this.previewWidget.bind(this)}>
						<Typography variant="body2">
							<I18n map="general_text.validate" />
						</Typography>
					</Button>
				</Box>
				<AtmoboxesMapGraph
					narrowedWidget={this.state.widgetPreview}
					setSelectedAtmoboxInfo={this.setSelectedAtmoboxInfo.bind(this)}
					interactive
				/>
				<Box sx={styles.atmoboxInfo}>
					<Box sx={styles.atmoboxRow}>
						<Box sx={styles.atmoNameAndNotarisation}>
							<Typography mb={1} variant="h6">
								{this.atmoboxName}
							</Typography>
							{this.getChip()}
						</Box>
						<Box sx={styles.row}>
							<Typography sx={styles.dataQuality}>
								<I18n map="components.widget.data_quality" />
							</Typography>
							<Tooltip arrow={true} title={<TooltipInfo />}>
								<IconButton>
									<InfoOutlinedIcon fontSize="inherit" style={{ color: "rgba(0, 0, 0, 0.6)" }} />
								</IconButton>
							</Tooltip>
						</Box>
					</Box>
					<hr />
					<Box sx={styles.atmoboxLocation}>
						<Typography mt={2} variant="h6">
							<I18n map="components.widgets.atmoboxes_map.graph.selected_atmobox_info.location" />
						</Typography>
						<Box sx={styles.row}>
							{this.state.geoloc && (
								<>
									<Typography sx={styles.data}>{this.convertCoordinateToDMS(this.state.geoloc.lat)}</Typography>
									<Typography ml={4} sx={styles.data}>
										{this.convertCoordinateToDMS(this.state.geoloc.lon)}
									</Typography>
								</>
							)}
							{!this.state.geoloc && <Typography sx={styles.data}>--</Typography>}
						</Box>
						<Typography mt={2} variant="h6">
							<I18n map="components.widgets.atmoboxes_map.graph.selected_atmobox_info.laboratory" />
						</Typography>
						<Typography sx={styles.data}>{this.state.geoloc ? "CEA Paris-Saclay" : "--"}</Typography>
					</Box>
				</Box>

				<MissingData missingData={getDCFilesMissingEntries(this.state.widgetPreview.data)} />
			</>
		);
	}

	public override componentDidUpdate(prevProps: Readonly<IProps>): void {
		if (this.props.narrowedWidget !== prevProps.narrowedWidget) {
			this.atmoboxName = capitalizeFirstLetter(this.state.selectedAtmobox?.name ?? "No atmobox");
			this.setState({
				widgetPreview: this.props.narrowedWidget,
			});
		}
	}

	private async previewWidget() {
		if (!this.state.selectedAtmobox) {
			return;
		}
		const widgetProps = {
			atmoboxId: this.state.selectedAtmobox.id,
			date: this.date,
		};

		try {
			const widgetPreview = await WidgetApi.getInstance().previewWidget(this.state.widgetPreview.id, widgetProps);
			this.setState({
				widgetPreview: { ...widgetPreview, props: widgetPreview.props as AtmoboxesMapProps },
			});
		} catch (error) {
			this.setState({ feedback: { message: I18n.translate("error_messages.preview_widget"), severity: "error" } });
		}

		this.props.setWidgetProps(widgetProps);
		this.atmoboxName = capitalizeFirstLetter(this.state.selectedAtmobox?.name ?? "No atmobox");
	}

	private setDate(newDate: Dayjs | null): void {
		if (!newDate) {
			return;
		}

		this.date = newDate.toDate();
		this.props.setWidgetProps({
			date: newDate.toDate(),
		} as AtmoboxesMapProps);
	}

	private convertCoordinateToDMS(coordinate: number): string {
		const degree = Math.floor(coordinate);
		const restDegree = coordinate - degree; // 0.4756

		const rawMinutes = restDegree * 60; //28.536
		const minutes = Math.floor(rawMinutes);

		const seconds = Math.floor((rawMinutes - minutes) * 60);

		return `${degree}°${minutes}"${seconds}'`;
	}

	private setSelectedAtmoboxFromSelect(atmobox: Atmobox | null): void {
		if (!atmobox) {
			return;
		}

		this.setState({ selectedAtmobox: atmobox });
		this.props.setWidgetProps({
			atmoboxId: atmobox.id,
		} as AtmoboxesMapProps);
	}

	private setSelectedAtmoboxInfo({ atmobox, quality, geoloc }: AtmoboxInfo): void {
		this.setState({ selectedAtmobox: atmobox, quality, geoloc });
		this.atmoboxName = capitalizeFirstLetter(atmobox.name);
		this.props.setWidgetProps({
			atmoboxId: atmobox.id,
		} as AtmoboxesMapProps);
	}

	private getChip(): JSX.Element {
		if (!this.state.quality || this.state.quality === 0) {
			return (
				<Chip
					label={I18n.translate(`components.data_quality_chip.no_info`)}
					color="info"
					size="small"
					style={{ padding: theme.spacing(2) }}
				/>
			);
		}
		if (this.state.quality <= 50) {
			return (
				<Chip
					label={I18n.translate(`components.data_quality_chip.bad`)}
					color="error"
					size="small"
					style={{ padding: theme.spacing(2) }}
				/>
			);
		}
		if (this.state.quality <= 95) {
			return (
				<Chip
					label={I18n.translate(`components.data_quality_chip.medium`)}
					color="warning"
					size="small"
					style={{ padding: theme.spacing(2) }}
				/>
			);
		}
		return (
			<Chip
				label={I18n.translate(`components.data_quality_chip.good`)}
				color="primary"
				size="small"
				style={{ padding: theme.spacing(2) }}
			/>
		);
	}
}

class TooltipInfo extends React.Component {
	override render() {
		return (
			<Box sx={styles.tooltipInfo}>
				<Box pt={2} sx={styles.row}>
					<CircleIcon color="success" fontSize="inherit" />
					<Typography ml={2}>
						<I18n map="components.data_quality_chip.good" />
					</Typography>
					<Typography sx={styles.data} ml={3}>
						<I18n map="components.data_quality.good" />
					</Typography>
				</Box>
				<Box pt={2} sx={styles.row}>
					<CircleIcon color="warning" fontSize="inherit" />
					<Typography ml={2}>
						<I18n map="components.data_quality_chip.medium" />
					</Typography>
					<Typography sx={styles.data} ml={3}>
						<I18n map="components.data_quality.medium" />
					</Typography>
				</Box>
				<Box pt={2} pb={2} sx={styles.row}>
					<CircleIcon color="error" fontSize="inherit" />
					<Typography ml={2}>
						<I18n map="components.data_quality_chip.bad" />
					</Typography>
					<Typography sx={styles.data} ml={3}>
						<I18n map="components.data_quality.bad" />
					</Typography>
				</Box>
				<Box pb={2} sx={styles.row}>
					<CircleIcon color="disabled" fontSize="inherit" />
					<Typography ml={2}>
						<I18n map="components.data_quality_chip.no_info" />
					</Typography>
					<Typography sx={styles.data} ml={3}>
						<I18n map="components.data_quality.none" />
					</Typography>
				</Box>
			</Box>
		);
	}
}

export default function AtmoboxesMapDetails(props: Omit<IProps, "mediaQueries">) {
	const mediaQueries = useMediaQueries();

	return <Component {...{ ...props, mediaQueries }} />;
}
