import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/UseMediaQueries";

export const widgetsWrapper: (mediaQueries: MediaQueries) => SxProps<Theme> = (mediaQueries) => (theme) => {
	return {
		...{
			"> *": {
				marginBottom: theme.spacing(3),
			},
			"> :last-child": {
				marginBottom: theme.spacing(0),
			},
		},
		...(mediaQueries.isMd && {
			minHeight: "1500px",
			display: "grid",
			gridTemplateColumns: "repeat(2, 1fr)",
			gridGap: theme.spacing(3),
			gridAutoRows: "repeat(6, 1fr)",
			"> *": {
				marginBottom: 0,
			},
		}),
	};
};

