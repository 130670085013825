import React from "react";
import { Box } from "@mui/material";
import { DashboardEntity } from "models/Dashboard";
import DashboardsStore from "stores/DashboardsStore";

import Toast, { ToastFeedback } from "components/elements/Toast";
import I18n from "components/materials/I18n";

import DashboardItem from "../DashboardItem";

import * as styles from "./styles";

type IProps = Record<string, never>;
type IState = {
	dashboards: DashboardEntity[];
	feedback?: ToastFeedback;
};

export default class DashboardList extends React.Component<IProps, IState> {
	private removeOnChange = () => {};
	private readonly dashboardsStore = DashboardsStore.getInstance();

	public constructor(props: IProps) {
		super(props);
		this.state = {
			dashboards: [],
			feedback: undefined,
		};
	}

	public override render(): React.ReactNode {
		return (
			<>
				<Toast feedback={this.state.feedback} onClose={() => this.setState({ feedback: undefined })} />
				<Box sx={styles.root}>
					{this.state.dashboards.map((dashboard) => (
						<DashboardItem key={dashboard.id} dashboard={dashboard} />
					))}
				</Box>
			</>
		);
	}

	public override async componentDidMount(): Promise<void> {
		this.removeOnChange = this.dashboardsStore.onChangeDashboards((dashboards) => {
			this.setState({
				dashboards,
			});
		});

		const dashboards = this.dashboardsStore.getDashboards();
		this.setState({
			dashboards,
		});
	}

	public override componentWillUnmount() {
		try {
			this.removeOnChange();
		} catch (err: unknown) {
			this.setState({ feedback: { message: I18n.translate("error_messages.select_dashboard"), severity: "error" } });
		}
	}
}

