import BaseApiService from "api/BaseApiService";
import { Blob } from "buffer";
import Config from "configs/Config";

export default class DCFileApi extends BaseApiService {
	private static instance: DCFileApi;
	private readonly baseUrl = this.rootUrl.concat(Config.getInstance().get().app).concat("/app-dc-files");

	private constructor() {
		super();
	}

	public static getInstance(): DCFileApi {
		return (this.instance = this.instance ?? new DCFileApi());
	}

	public async downloadFiles(urls: string[]): Promise<Blob> {
		const url = new URL(this.baseUrl);
		try {
			return await this.postDownload(url, { urls });
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}

