import React from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
	Box,
	Chip,
	Collapse,
	Dialog,
	DialogContent,
	List,
	ListItem,
	ListItemText,
	Paper,
	Typography,
} from "@mui/material";
import { getNotarizationColor, getNotarizationStatus } from "helpers/atmobox";
import { capitalizeFirstLetter } from "helpers/text";
import { Atmobox, AtmoboxFile } from "models/Atmobox";
import { WidgetEntity } from "models/Widget";

import DialogHeader from "components/elements/DialogHeader";
import I18n from "components/materials/I18n";

import SearchBar from "../SearchBar";

import NotarisationTable from "./notarisationTable";
import * as styles from "./styles";

type IProps = {
	open: boolean;
	close: () => void;
	widget: WidgetEntity;
};

type IState = {
	selectedAtmoboxes: Atmobox[];
	searchText: string;
};

export default class DialogNotorisation extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			selectedAtmoboxes: [],
			searchText: "",
		};
	}
	public override render(): React.ReactNode {
		return (
			<Dialog maxWidth="xl" fullWidth={true} open={this.props.open} onClose={this.props.close}>
				<DialogHeader close={this.props.close}>
					<Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
						<Typography mr={3} variant={"h5"}>
							<I18n map="components.notarization_dialog.title" />
						</Typography>
						<Chip
							label={I18n.translate(`components.notarization_chip.${getNotarizationStatus(this.props.widget.data)}`)}
							color={getNotarizationColor(getNotarizationStatus(this.props.widget.data))}
							size="small"
						/>
					</Box>
				</DialogHeader>

				<DialogContent sx={styles.content}>
					<SearchBar setSearchQuery={this.filterFiles.bind(this)} />
					{this.props.widget.widgetType.type === "co2_average" && (
						<NotarisationTable files={this.props.widget.data.flatMap((a) => this.getAtmoboxFiles(a))} />
					)}
					{this.props.widget.widgetType.type === "co2_evolution" && (
						<List component="nav">
							{this.props.widget.data.map((atmobox) => {
								const open = !!this.state.selectedAtmoboxes.find((a) => a.id === atmobox.id);
								return (
									<Paper key={atmobox.id} elevation={1} style={{ marginTop: "1px" }}>
										<ListItem style={{ cursor: "pointer" }} onClick={() => this.openAccordion(atmobox)}>
											<ListItemText primary={capitalizeFirstLetter(atmobox.name)} />
											{open ? <ExpandLess /> : <ExpandMore />}
										</ListItem>
										<Collapse in={open} timeout="auto" unmountOnExit>
											<NotarisationTable files={this.getAtmoboxFiles(atmobox)} />
										</Collapse>
									</Paper>
								);
							})}
						</List>
					)}
				</DialogContent>
			</Dialog>
		);
	}

	private filterFiles(searchText: string) {
		if (searchText) {
			this.setState({
				selectedAtmoboxes: this.props.widget.data.filter((a) =>
					this.getAtmoboxFiles(a).find((f) => f.fileMeta?.name.toLowerCase().includes(searchText)),
				),
				searchText,
			});
		}
	}

	private openAccordion(atmobox: Atmobox) {
		const selectedAtmoboxIndex = this.state.selectedAtmoboxes.findIndex((a) => a.id === atmobox.id);
		const isSelected = selectedAtmoboxIndex !== -1;

		if (isSelected && this.state.selectedAtmoboxes.length) {
			const newSelectedAtmoboxes = this.state.selectedAtmoboxes;
			newSelectedAtmoboxes.splice(selectedAtmoboxIndex, 1);

			this.setState({
				selectedAtmoboxes: newSelectedAtmoboxes,
			});
		} else {
			this.setState({
				selectedAtmoboxes: [...this.state.selectedAtmoboxes, atmobox],
			});
		}
	}

	private getAtmoboxFiles(atmobox: Atmobox) {
		const files = atmobox.fileEpoches
			?.flatMap((fileEpoch) => {
				return [fileEpoch.calFile, fileEpoch.dcFile, fileEpoch.qcFile, fileEpoch.rawFile];
			})
			.filter(
				(file) => file?.fileMeta && file.fileMeta.name.toLowerCase().includes(this.state.searchText.toLowerCase()),
			) as AtmoboxFile[];

		if (!files?.length) {
			return [];
		}

		return files;
	}
}
