import BaseApiService from "api/BaseApiService";
import Config from "configs/Config";
import { EntityId } from "models";
import { DashboardEntity } from "models/Dashboard";

export default class DashboardApi extends BaseApiService {
	private static instance: DashboardApi;
	private readonly baseUrl = this.rootUrl.concat(Config.getInstance().get().app).concat("/app-dashboards");

	private constructor() {
		super();
	}

	public static getInstance(): DashboardApi {
		return (this.instance = this.instance ?? new DashboardApi());
	}

	public async getDashboards(): Promise<DashboardEntity[]> {
		const url = new URL(this.baseUrl);
		try {
			return await this.getRequest<DashboardEntity[]>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getDashboard(dashboardId: EntityId): Promise<DashboardEntity> {
		const url = new URL(this.baseUrl.concat(`/${dashboardId}`));
		try {
			return await this.getRequest<DashboardEntity>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async createDashboard(): Promise<DashboardEntity> {
		const url = new URL(this.baseUrl);
		try {
			return await this.postRequest<DashboardEntity>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async renameDashboard(dashboardId: EntityId, dashboardName: string): Promise<DashboardEntity> {
		const url = new URL(this.baseUrl.concat(`/${dashboardId}`));
		try {
			return await this.patchRequest<DashboardEntity>(url, { id: dashboardId, name: dashboardName });
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async duplicateDashboard(dashboardId: EntityId): Promise<DashboardEntity> {
		const url = new URL(this.baseUrl.concat(`/${dashboardId}`));
		try {
			return await this.postRequest<DashboardEntity>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async deleteDashboard(dashboardId: EntityId): Promise<void> {
		const url = new URL(this.baseUrl.concat(`/${dashboardId}`));
		try {
			await this.deleteRequest<void>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async addWidget(dashboardId: EntityId, widgetId: EntityId): Promise<DashboardEntity> {
		const url = new URL(this.baseUrl.concat(`/${dashboardId}/app-widgets`));
		try {
			return await this.postRequest<DashboardEntity>(url, { dashboardId, baseWidgetId: widgetId });
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getDefaultDashboard(): Promise<DashboardEntity | null> {
		const url = new URL(this.baseUrl.concat(`/default-dashboard`));
		try {
			return await this.getRequest<DashboardEntity>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
