import React from "react";
import { Box, Button } from "@mui/material";
import useMediaQueries, { MediaQueries } from "hooks/UseMediaQueries";
import { DashboardEntity } from "models/Dashboard";
import SelectedDashboardStore from "stores/SelectedDashboardStore";

import Toast, { ToastFeedback } from "components/elements/Toast";
import Widget from "components/materials/Widget";

import I18n from "../I18n";
import AddWidgetDialog from "../widgets/materials/DialogAddWidget";

import * as styles from "./styles";

type IProps = {
	mediaQueries: MediaQueries;
};
type IState = {
	dashboard: DashboardEntity | null;
	isEditing: boolean;
	isAddingWidget: boolean;
	feedback?: ToastFeedback;
};

class Component extends React.Component<IProps, IState> {
	private removeOnChangeIsEditing = () => {};
	private removeOnChangeSelectedDashboard = () => {};
	private selectedDashboardStore = SelectedDashboardStore.getInstance();

	public constructor(props: IProps) {
		super(props);
		this.state = {
			dashboard: null,
			isEditing: false,
			isAddingWidget: false,
			feedback: undefined,
		};
	}

	public override render(): React.ReactNode {
		return (
			<>
				<Toast feedback={this.state.feedback} onClose={() => this.setState({ feedback: undefined })} />
				<AddWidgetDialog open={this.state.isAddingWidget} close={this.closeAddWidgetsDialog.bind(this)} />
				{this.state.isEditing && <Box sx={styles.background} />}
				<Box sx={styles.root}>
					<Box sx={styles.widgetsWrapper(this.props.mediaQueries)}>
						{this.state.dashboard?.widgets?.map((widget) => (
							<Widget key={widget.id} widget={widget} isEditing={this.state.isEditing} />
						))}
					</Box>
					{this.state.isEditing && (
						<Button variant="contained" sx={styles.addWidgetButton} onClick={this.showAddWidgetsDialog.bind(this)}>
							<I18n map="components.dashboard.add_widget_button" />
						</Button>
					)}
				</Box>
			</>
		);
	}

	public override componentDidMount(): void {
		this.removeOnChangeSelectedDashboard = this.selectedDashboardStore.onChangeSelected((selectedDashboard) => {
			this.setState({
				dashboard: selectedDashboard,
			});
		});

		this.removeOnChangeIsEditing = this.selectedDashboardStore.onChangeIsEditing((isEditing) => {
			this.setState({
				isEditing,
			});
		});
	}

	public override componentWillUnmount(): void {
		try {
			this.removeOnChangeSelectedDashboard();
			this.removeOnChangeIsEditing();
		} catch (err: unknown) {
			this.setState({ feedback: { message: I18n.translate("error_messages.select_dashboard"), severity: "error" } });
		}
	}

	private showAddWidgetsDialog() {
		this.setState({
			isAddingWidget: true,
		});
	}

	private closeAddWidgetsDialog() {
		this.setState({
			isAddingWidget: false,
		});
	}
}

export default function Dashboard(props: Omit<IProps, "mediaQueries">) {
	const mediaQueries = useMediaQueries();

	return <Component {...{ ...props, mediaQueries }} />;
}

