import React from "react";
import { Check, Close } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { DashboardEntity } from "models/Dashboard";
import DashboardsStore from "stores/DashboardsStore";
import SelectedDashboardStore from "stores/SelectedDashboardStore";

import Toast, { ToastFeedback } from "components/elements/Toast";
import I18n from "components/materials/I18n";

import * as styles from "./styles";

type IProps = {
	dashboard: DashboardEntity;
	closeRename: () => void;
};
type IState = {
	feedback?: ToastFeedback;
	nameValue: string;
};

export default class RenameDashboard extends React.Component<IProps, IState> {
	private readonly dashboardsStore = DashboardsStore.getInstance();
	private readonly selectedDashboardStore = SelectedDashboardStore.getInstance();
	public constructor(props: IProps) {
		super(props);
		this.state = {
			feedback: undefined,
			nameValue: this.props.dashboard.name,
		};
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleConfirm = this.handleConfirm.bind(this);
	}

	public override render(): React.ReactNode {
		return (
			<>
				<Toast feedback={this.state.feedback} onClose={() => this.setState({ feedback: undefined })} />
				<Box sx={styles.background} onClick={this.props.closeRename} />
				<TextField
					sx={styles.root}
					autoFocus
					onChange={(event) => this.setState({ nameValue: event.target.value })}
					defaultValue={this.props.dashboard.name}
					onKeyDown={this.handleKeyDown}
					InputProps={{
						sx: styles.input,
						endAdornment: (
							<InputAdornment position="end">
								<IconButton size="small" onClick={this.handleCancel}>
									<Close fontSize="small" color="info" />
								</IconButton>
								<IconButton size="small" onClick={this.handleConfirm}>
									<Check fontSize="small" color="success" />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</>
		);
	}

	private async handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
		event.stopPropagation();

		if (event.code === "Enter" && this.state.nameValue) {
			this.renameDashboard();
		}

		if (event.code === "Escape") {
			this.props.closeRename();
		}
	}

	private handleCancel(event: React.UIEvent) {
		event.stopPropagation();
		this.props.closeRename();
	}

	private async handleConfirm(event: React.UIEvent) {
		event.stopPropagation();
		if (this.state.nameValue) {
			this.renameDashboard();
		}
	}

	private async renameDashboard() {
		try {
			await this.dashboardsStore.renameDashboard(this.props.dashboard.id, this.state.nameValue);
			if (this.selectedDashboardStore.getDashboard()?.id === this.props.dashboard.id) {
				this.selectedDashboardStore.fetchDashboard();
			}
			this.props.closeRename();
		} catch (err: unknown) {
			this.setState({ feedback: { message: I18n.translate("error_messages.rename_dashboard"), severity: "error" } });
		}
	}
}

