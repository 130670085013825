import { SxProps, Theme } from "@mui/material";

export const data: SxProps<Theme> = (theme) => {
	return {
		fontFamily: "Inter",
		fontStyle: "normal",
		fontWeight: 700,
		fontSize: "34px",
		lineHeight: "123.5%",
		letterSpacing: "0.25px",
		color: theme.palette.primary.main,
	};
};

export const unit: SxProps<Theme> = (theme) => {
	return {
		paddingLeft: theme.spacing(1),
		paddingTop: theme.spacing(2),
		fontFamily: "Inter",
		fontStyle: "normal",
		fontWeight: 700,
		fontSize: "20px",
		lineHeight: "160%",
		letterSpacing: "0.15px",
		color: theme.palette.primary.main,
	};
};

export const averageCo2: SxProps<Theme> = () => {
	return {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	};
};

