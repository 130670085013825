import React from "react";
import { Box, Typography } from "@mui/material";
import { Co2AverageProps, WidgetEntity } from "models/Widget";

import I18n from "components/materials/I18n";

import * as styles from "./styles";

type IProps = {
	narrowedWidget: WidgetEntity & {
		props: Co2AverageProps;
	};
};

type IState = Record<string, never>;

export default class Co2AverageGraph extends React.Component<IProps, IState> {
	override render() {
		const average = this.getCo2EntriesAverage();
		return (
			<Box sx={styles.averageCo2}>
				{(average && (
					<>
						<Typography sx={styles.data}>{this.getCo2EntriesAverage()}</Typography>
						<Typography sx={styles.unit}>ppm</Typography>
					</>
				)) || (
					<Typography sx={styles.unit}>
						<I18n map="general_text.no_data" />
					</Typography>
				)}
			</Box>
		);
	}

	private getCo2EntriesAverage() {
		const co2Entries =
			this.props.narrowedWidget.data[0]?.fileEpoches
				?.map((fileEpoch) => fileEpoch.dcFile?.dcEntries?.map((dcEntry) => dcEntry.co2) || [])
				.flat() || [];

		if (!co2Entries.length) {
			return 0;
		}

		return +(co2Entries.reduce((acc, co2Consumption) => co2Consumption + acc, 0) / co2Entries.length).toFixed(2);
	}
}

