import { SxProps, Theme } from "@mui/material";

export const header: SxProps<Theme> = {
	minWidth: "100%",
	margin: "0px",
};

export const getPadding: SxProps<Theme> = (theme) => {
	return {
		paddingBottom: theme.spacing(4),
	};
};
