import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import { format } from "date-fns";
import { capitalizeFirstLetter } from "helpers/text";
import useMediaQueries, { MediaQueries } from "hooks/UseMediaQueries";
import { AtmoboxesMapProps, WidgetEntity } from "models/Widget";

import AtmoboxesMapGraph, { AtmoboxInfo } from "../../content/AtmoboxesMapGraph";

import * as styles from "./styles";

type IProps = {
	mediaQueries: MediaQueries;
	narrowedWidget: WidgetEntity & {
		props: AtmoboxesMapProps;
	};
};
type IState = {
	selectedAtmoboxInfo: AtmoboxInfo | null;
};

class Component extends React.Component<IProps, IState> {
	public constructor(props: IProps) {
		super(props);
		this.state = {
			selectedAtmoboxInfo: null,
		};
	}
	public override render() {
		return (
			<>
				<Box sx={styles.root}>
					<AtmoboxesMapGraph
						narrowedWidget={this.props.narrowedWidget}
						interactive={false}
						setSelectedAtmoboxInfo={this.setSelectedAtmoboxInfo.bind(this)}
					/>
					<Paper sx={styles.atmoboxInfo}>
						<Typography sx={styles.atmoboxName} mr={2}>
							{capitalizeFirstLetter(this.state.selectedAtmoboxInfo?.atmobox.name)}
						</Typography>
						<Typography>{format(new Date(this.props.narrowedWidget.props.date), "dd/MM/yyyy ● HH:mm")}</Typography>
					</Paper>
				</Box>
			</>
		);
	}

	private setSelectedAtmoboxInfo(atmoboxInfo: AtmoboxInfo): void {
		this.setState({ selectedAtmoboxInfo: atmoboxInfo });
	}
}

export default function AtmoboxesMapView(props: Omit<IProps, "mediaQueries">) {
	const mediaQueries = useMediaQueries();

	return <Component {...{ ...props, mediaQueries }} />;
}

