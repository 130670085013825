import React from "react";
import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";

import DialogHeader from "components/elements/DialogHeader";
import I18n from "components/materials/I18n";

type IProps = {
	open: boolean;
	close: () => void;
	onDelete: (event: React.UIEvent) => void;
};
type IState = Record<string, never>;

export default class DialogDeleteDashboard extends React.Component<IProps, IState> {
	public override render(): React.ReactNode {
		return (
			<Dialog open={this.props.open} onClose={this.props.close}>
				<DialogHeader close={this.props.close}>
					<Typography variant="h6">
						<I18n map="components.delete_dashboard_dialog.title" />
					</Typography>
				</DialogHeader>

				<DialogContent>
					<Typography variant="body1">
						<I18n map="components.delete_dashboard_dialog.description" />
					</Typography>
				</DialogContent>

				<DialogActions>
					<Button variant="outlined" color="info" onClick={this.props.close}>
						<Typography variant="body2" ml={2}>
							<I18n map="general_text.cancel" />
						</Typography>
					</Button>
					<Button variant="contained" color="error" onClick={this.props.onDelete}>
						<Typography variant="body2">
							<I18n map="general_text.delete" />
						</Typography>
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

