import React from "react";
import { Box, Theme, Typography, useTheme } from "@mui/material";
import useMediaQueries, { MediaQueries } from "hooks/UseMediaQueries";
import { SystemEnergyCostProps, WidgetEntity } from "models/Widget";

import I18n from "components/materials/I18n";

import SystemEnergyCostGraph from "../../content/SystemEnergyEnergyCostGraph";

import * as styles from "./styles";

type IProps = {
	narrowedWidget: WidgetEntity & {
		props: SystemEnergyCostProps;
	};
	mediaQueries: MediaQueries;
	theme: Theme;
};
type IState = Record<string, never>;

class Component extends React.Component<IProps, IState> {
	public override render(): React.ReactNode {
		return (
			<Box style={{ marginTop: this.props.theme.spacing(4) }}>
				<Box sx={styles.graphRow}>
					<SystemEnergyCostGraph narrowedWidget={this.props.narrowedWidget} />
					<hr />
					<Typography sx={styles.energyComparison(this.props.mediaQueries)}>
						<I18n map="components.widget.energy_cost_comparaison" />
					</Typography>
				</Box>
			</Box>
		);
	}
}

export default function SystemEnergyCostGraphRow(props: Omit<IProps, "mediaQueries" | "theme">) {
	const mediaQueries = useMediaQueries();
	const theme = useTheme();

	return <Component {...{ ...props, mediaQueries, theme }} />;
}
