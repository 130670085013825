import { SxProps, Theme } from "@mui/material";

export const background: SxProps<Theme> = (theme) => ({
	position: "fixed",
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
	zIndex: theme.zIndex.fab,
});

export const root: SxProps<Theme> = (theme) => ({
	display: "flex",
	zIndex: theme.zIndex.modal,
});

export const input: SxProps<Theme> = (theme) => ({
	height: theme.spacing(6),
	marginBottom: theme.spacing(1),
});
