import { SxProps, Theme } from "@mui/material";

export const root: SxProps<Theme> = {
	display: "flex",
};

export const content: SxProps<Theme> = (theme) => {
	return {
		display: "flex",
		flex: 1,
		backgroundColor: theme.palette.background.default,
	};
};

