import React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { ContentCopyOutlined, DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Box, Button, Menu, Typography } from "@mui/material";
import { DashboardEntity } from "models/Dashboard";
import DashboardsStore from "stores/DashboardsStore";
import SelectedDashboardStore from "stores/SelectedDashboardStore";

import Toast, { ToastFeedback } from "components/elements/Toast";
import DialogDeleteDashboard from "components/materials/DialogDeleteDashboard";
import I18n from "components/materials/I18n";

import * as styles from "./styles";

type IProps = {
	navigate: NavigateFunction;
	anchorEl: HTMLButtonElement | null;
	dashboard: DashboardEntity;
	showRename: () => void;
	closeMenu: () => void;
};
type IState = {
	isDeleting: boolean;
	feedback?: ToastFeedback;
};

class Component extends React.Component<IProps, IState> {
	private readonly dashboardsStore = DashboardsStore.getInstance();
	private readonly selectedDashboardStore = SelectedDashboardStore.getInstance();

	public constructor(props: IProps) {
		super(props);
		this.state = {
			isDeleting: false,
			feedback: undefined,
		};

		this.showRename = this.showRename.bind(this);
		this.duplicateDashboard = this.duplicateDashboard.bind(this);
		this.deleteDashboard = this.deleteDashboard.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
		this.showDeleteDashboard = this.showDeleteDashboard.bind(this);
		this.closeDeleteDashboard = this.closeDeleteDashboard.bind(this);
	}

	public override render(): React.ReactNode {
		return (
			<>
				<Toast feedback={this.state.feedback} onClose={() => this.setState({ feedback: undefined })} />
				<DialogDeleteDashboard
					open={this.state.isDeleting}
					close={this.closeDeleteDashboard.bind(this)}
					onDelete={this.deleteDashboard.bind(this)}
				/>
				<Menu
					open
					onClose={this.props.closeMenu}
					anchorEl={this.props.anchorEl}
					sx={styles.root}
					onClick={this.closeMenu}>
					<Box sx={styles.content}>
						<Button sx={styles.button} color="info" onClick={this.showRename}>
							<EditOutlined sx={styles.icon} />
							<Typography variant="body2">
								<I18n map="components.side_menu.menu_more.rename_button" />
							</Typography>
						</Button>

						<Button sx={styles.button} color="info" onClick={this.duplicateDashboard}>
							<ContentCopyOutlined sx={styles.icon} />
							<Typography variant="body2">
								<I18n map="components.side_menu.menu_more.duplicate_button" />
							</Typography>
						</Button>

						<Button sx={styles.button} color="info" onClick={this.showDeleteDashboard}>
							<DeleteOutline sx={styles.icon} color="error" />
							<Typography variant="body2" color="error">
								<I18n map="components.side_menu.menu_more.delete_button" />
							</Typography>
						</Button>
					</Box>
				</Menu>
			</>
		);
	}

	private showDeleteDashboard(event: React.UIEvent) {
		event.stopPropagation();
		this.setState({ isDeleting: true });
	}

	private closeDeleteDashboard() {
		this.setState({ isDeleting: false });
	}

	private showRename(event: React.UIEvent) {
		event.stopPropagation();
		this.props.showRename();
	}

	private async duplicateDashboard(event: React.UIEvent) {
		event.stopPropagation();

		try {
			await this.dashboardsStore.duplicateDashboard(this.props.dashboard.id);
			this.props.closeMenu();
		} catch {
			this.setState({ feedback: { message: I18n.translate("error_messages.duplicate_dashboard"), severity: "error" } });
		}
	}

	private async deleteDashboard(event: React.UIEvent) {
		event.stopPropagation();
		try {
			await this.dashboardsStore.deleteDashboard(this.props.dashboard.id);
			this.props.closeMenu();

			const isNotSelectedDashboard = this.selectedDashboardStore.getDashboard()?.id !== this.props.dashboard.id;
			if (isNotSelectedDashboard) {
				return;
			}

			const fallbackDashboardId = this.dashboardsStore.getDashboards()[0]?.id;

			this.props.navigate(`${fallbackDashboardId || ""}`);
		} catch {
			this.setState({ feedback: { message: I18n.translate("error_messages.delete_dashboard"), severity: "error" } });
		}
	}

	private closeMenu(event: React.UIEvent) {
		event.stopPropagation();
		this.props.closeMenu();
	}
}

export default function MenuMore(props: Omit<IProps, "navigate">) {
	const navigate = useNavigate();

	return <Component {...{ ...props, navigate }} />;
}

