import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/UseMediaQueries";

export const root: SxProps<Theme> = (theme) => ({
	display: "flex",
	flexDirection: "column",
	flex: 1,
	padding: theme.spacing(3),
});

export const widgetsWrapper: (mediaQueries: MediaQueries) => SxProps<Theme> = (mediaQueries) => (theme) => {
	return {
		...{
			display: "grid",
			gridTemplateColumns: "1",
			gridGap: theme.spacing(3),
			gridAutoRows: "minmax(242px)",
		},
		...(mediaQueries.is1000 && {
			maxWidth: "undo",
			gridTemplateColumns: "repeat(2, 1fr)",
		}),
	};
};

export const addWidgetButton: SxProps<Theme> = (theme) => {
	return {
		margin: theme.spacing(4, "auto", 0),
		zIndex: theme.zIndex.modal,
	};
};

export const background: SxProps<Theme> = (theme) => ({
	position: "fixed",
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
	zIndex: theme.zIndex.fab,
});
