import React from "react";
import { Box, Button, Typography } from "@mui/material";
import WidgetApi from "api/WidgetApi";
import dayjs, { Dayjs } from "dayjs";
import { getDCFilesMissingEntries } from "helpers/atmobox";
import useMediaQueries, { MediaQueries } from "hooks/UseMediaQueries";
import { SystemEnergyCostProps, WidgetEntity } from "models/Widget";

import DatePicker from "components/elements/DatePicker";
import MissingData from "components/elements/MissingData";
import Toast, { ToastFeedback } from "components/elements/Toast";
import I18n from "components/materials/I18n";

import SystemEnergyCostGraphRow from "../../materials/SystemEnergyCostGraphRow";

type IProps = {
	narrowedWidget: WidgetEntity & {
		props: SystemEnergyCostProps;
	};
	setWidgetProps: (widgetProps: WidgetEntity["props"]) => void;
	mediaQueries: MediaQueries;
};
type IState = {
	widgetPreview: WidgetEntity & {
		props: SystemEnergyCostProps;
	};
	feedback?: ToastFeedback;
};

class Component extends React.Component<IProps, IState> {
	private fromDate: Date;
	private toDate: Date;

	public constructor(props: IProps) {
		super(props);
		this.state = {
			widgetPreview: this.props.narrowedWidget,
			feedback: undefined,
		};

		this.fromDate = this.state.widgetPreview.props.from;
		this.toDate = this.state.widgetPreview.props.to;
	}

	public override render(): React.ReactNode {
		return (
			<>
				<Toast feedback={this.state.feedback} onClose={() => this.setState({ feedback: undefined })} />
				<Box sx={(theme) => ({ paddingBottom: theme.spacing(4) })}>
					<DatePicker
						setFromDate={this.setFromDate}
						setToDate={this.setToDate}
						fromDate={dayjs(this.state.widgetPreview.props.from)}
						toDate={dayjs(this.state.widgetPreview.props.to)}
					/>
				</Box>
				<Button variant="contained" onClick={this.previewWidget.bind(this)}>
					<Typography variant="body2">
						<I18n map="general_text.validate" />
					</Typography>
				</Button>
				<SystemEnergyCostGraphRow narrowedWidget={this.state.widgetPreview} />
				<MissingData missingData={getDCFilesMissingEntries(this.state.widgetPreview.data)} />
			</>
		);
	}

	public override componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {
		if (this.state.widgetPreview !== prevState.widgetPreview) {
			this.fromDate = this.state.widgetPreview.props.from;
			this.toDate = this.state.widgetPreview.props.to;
		}

		if (this.props.narrowedWidget !== prevProps.narrowedWidget) {
			this.setState({
				widgetPreview: this.props.narrowedWidget,
			});
		}
	}

	private setFromDate = (newFromDate: Dayjs | null): void => {
		if (!newFromDate) {
			return;
		}
		this.fromDate = new Date(newFromDate.toString());
		this.updateWidgetProps();
	};

	private setToDate = (newToDate: Dayjs | null): void => {
		if (!newToDate) {
			return;
		}
		this.toDate = new Date(newToDate.toString());
		this.updateWidgetProps();
	};

	private updateWidgetProps() {
		this.props.setWidgetProps({
			from: this.fromDate,
			to: this.toDate,
		});
	}

	private async previewWidget() {
		try {
			const widgetPreview = await WidgetApi.getInstance().previewWidget(this.state.widgetPreview.id, {
				from: this.fromDate,
				to: this.toDate,
			});

			this.setState({
				widgetPreview: { ...widgetPreview, props: widgetPreview.props as SystemEnergyCostProps },
			});
		} catch (err: unknown) {
			this.setState({ feedback: { message: I18n.translate("error_messages.preview_widget"), severity: "error" } });
		}
	}
}

export default function SystemEnergyCostDetails(props: Omit<IProps, "mediaQueries">) {
	const mediaQueries = useMediaQueries();

	return <Component {...{ ...props, mediaQueries }} />;
}

