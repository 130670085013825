import { SxProps, Theme } from "@mui/material";

export const dateContainer: SxProps<Theme> = {
	display: "flex",
	flexDirection: "row",
	alignItems: "start",
	width: "100%",
};

export const fromContent: SxProps<Theme> = {};

export const toContent: SxProps<Theme> = {};

export const dateInfo: SxProps<Theme> = {
	height: "22px",
	fontFamily: "Inter",
	fontStyle: "normal",
	fontWeight: 600,
	fontSize: "14px",
	lineHeight: "157%",
	letterSpacing: "0.1px",
	color: "#61A963",
};

export const date: SxProps<Theme> = {
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
};

export const arrowIcon: SxProps<Theme> = (theme) => {
	return {
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		color: theme.palette.primary.main,
	};
};

