import React from "react";
import { Box, Typography } from "@mui/material";
import { capitalizeFirstLetter } from "helpers/text";
import useMediaQueries, { MediaQueries } from "hooks/UseMediaQueries";
import { Atmobox } from "models/Atmobox";
import { Co2RelativeAverageProps, WidgetEntity } from "models/Widget";

import I18n from "components/materials/I18n";
import DateOfData from "components/materials/widgets/materials/DateOfData";

import CO2RelativeAverageGraph from "../../content/Co2RelativeAverageGraph";

import * as styles from "./styles";

type IProps = {
	mediaQueries: MediaQueries;
	narrowedWidget: WidgetEntity & {
		props: Co2RelativeAverageProps;
	};
};

type IState = {
	options: ApexCharts.ApexOptions | undefined;
	series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
};

class Component extends React.Component<IProps, IState> {
	private selectedAtmobox: Atmobox | null = null;
	private fromDate: Date | null = null;
	private toDate: Date | null = null;
	private atmoboxes: Atmobox[] = [];
	public constructor(props: IProps) {
		super(props);
		this.deriveProps();
	}

	public override render() {
		if (!this.atmoboxes.length || !this.selectedAtmobox || !this.fromDate || !this.toDate) {
			return (
				<Typography variant="body2">
					<I18n map="general_text.no_data" />
				</Typography>
			);
		}

		return (
			<Box style={{ maxHeight: "390px" }}>
				<Typography variant="body2" mr={2} sx={styles.atmoName}>
					{capitalizeFirstLetter(this.selectedAtmobox?.name ?? I18n.translate("general_text.no_data"))}
				</Typography>
				<DateOfData fromDate={this.fromDate} toDate={this.toDate} />
				<CO2RelativeAverageGraph narrowedWidget={this.props.narrowedWidget} />
			</Box>
		);
	}

	private deriveProps() {
		this.atmoboxes = this.props.narrowedWidget.data;
		const targetAtmoboxId = this.props.narrowedWidget.props.atmoboxId;
		this.selectedAtmobox = this.atmoboxes.find((atmobox) => atmobox.id === targetAtmoboxId) || null;
		this.fromDate = new Date(this.props.narrowedWidget.props.from);
		this.toDate = new Date(this.props.narrowedWidget.props.to);
	}
}

export default function Co2RelativeAverageView(props: Omit<IProps, "mediaQueries">) {
	const mediaQueries = useMediaQueries();

	return <Component {...{ ...props, mediaQueries }} />;
}
