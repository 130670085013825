import { NavigateFunction, Params, useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import useMediaQueries, { MediaQueries } from "hooks/UseMediaQueries";
import { DashboardEntity } from "models/Dashboard";
import DashboardsStore from "stores/DashboardsStore";
import SelectedDashboardStore from "stores/SelectedDashboardStore";

import Toast, { ToastFeedback } from "components/elements/Toast";
import Dashboard from "components/materials/Dashboard";
import DashboardsTopBar from "components/materials/DashboardTopBar";
import I18n from "components/materials/I18n";
import SideMenu from "components/materials/SideMenu";
import BasePage from "components/pages/Base";
import DefaultTemplate from "components/pageTemplates/DefaultTemplate";

type IProps = {
	params: Params;
	navigate: NavigateFunction;
	mediaQueries: MediaQueries;
};
type IState = {
	feedback?: ToastFeedback;
};

class Component extends BasePage<IProps, IState> {
	private readonly selectedDashboardStore = SelectedDashboardStore.getInstance();
	private readonly dashboardsStore = DashboardsStore.getInstance();

	public constructor(props: IProps) {
		super(props);

		this.state = {
			feedback: undefined,
		};
	}

	public override render(): React.ReactNode {
		return (
			<>
				<Toast feedback={this.state.feedback} onClose={() => this.setState({ feedback: undefined })} />
				<I18n
					map={"pages.dashboards.title"}
					content={([title]) => (
						<DefaultTemplate title={title} padding={false}>
							<Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
								<DashboardsTopBar />
								<Box sx={{ display: "flex", flex: 1, height: "100%" }}>
									{this.props.mediaQueries?.isMd && <SideMenu key="" />}
									<Dashboard />
								</Box>
							</Box>
						</DefaultTemplate>
					)}
				/>
			</>
		);
	}

	private async initSelectedDashboard() {
		const dashboardId = Number(this.props.params?.["*"]) || null;
		let selectedDashboard: DashboardEntity | null;
		try {
			await this.selectedDashboardStore.setDashboardId(dashboardId);
			selectedDashboard = this.selectedDashboardStore.getDashboard();
		} catch {
			this.setState({ feedback: { message: I18n.translate("error_messages.select_dashboard"), severity: "error" } });
			selectedDashboard = null;
		}

		if (selectedDashboard) {
			return;
		}

		const fallbackDashboardId = this.dashboardsStore.getDashboards()[0]?.id || null;
		await this.selectedDashboardStore.setDashboardId(fallbackDashboardId);

		this.props.navigate(`${fallbackDashboardId || ""}`);
	}

	public override async componentDidMount(): Promise<void> {
		try {
			await this.dashboardsStore.initDashboards();
		} catch (error) {
			this.setState({ feedback: { message: I18n.translate("error_messages.dashboards"), severity: "error" } });
		}

		await this.initSelectedDashboard();
	}

	public override async componentDidUpdate() {
		await this.initSelectedDashboard();
	}
}

export default function Dashboards() {
	const navigate = useNavigate();
	const params = useParams();
	const mediaQueries = useMediaQueries();

	return <Component {...{ navigate, params, mediaQueries }} />;
}

