import { SxProps, Theme } from "@mui/material";
import { sideMenuWidth } from "themes/constants";

export const root: SxProps<Theme> = (theme) => {
	return {
		...{
			width: sideMenuWidth,
			minWidth: sideMenuWidth,
			padding: theme.spacing(2),
			backgroundColor: theme.palette.background.paper,
		},
	};
};

