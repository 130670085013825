import AtmoboxApi from "api/Atmobox";
import DashboardApi from "api/Dashboard";
import { EntityId } from "models";
import { Atmobox, AtmoboxesConfig } from "models/Atmobox";
import { DashboardEntity } from "models/Dashboard";
import EventService from "services/EventEmitter";

export default class DashboardsStore {
	private static instance: DashboardsStore;
	private readonly event = new EventService();
	private readonly dashboardApi = DashboardApi.getInstance();
	private readonly atmoboxApi = AtmoboxApi.getInstance();

	private dashboards: DashboardEntity[] = [];
	private defaultDashboard: DashboardEntity | null = null;
	private atmoboxes: Atmobox[] = [];
	private atmoboxesConfig: AtmoboxesConfig | null = null;

	public static getInstance(): DashboardsStore {
		return (this.instance = this.instance ?? new DashboardsStore());
	}

	private async fetchDashboards() {
		const dashboards = await this.dashboardApi.getDashboards();
		this.setDashboards(dashboards);
	}

	private async fetchDefaultDashboard() {
		const dashboard = await this.dashboardApi.getDefaultDashboard();
		this.setDefaultDashboard(dashboard);
	}

	private async fetchAtmoboxes() {
		const atmoboxes = await this.atmoboxApi.getAtmoboxes();
		this.setAtmoboxes(atmoboxes);
	}

	private async fetchAtmoboxesConfig() {
		const atmoboxesConfig = await this.atmoboxApi.getAtmoboxesConfig();
		this.setAtmoboxesConfig(atmoboxesConfig);
	}

	public async initDashboards() {
		await this.fetchDefaultDashboard();
		await this.fetchDashboards();
		await this.fetchAtmoboxes();
		await this.fetchAtmoboxesConfig();
	}

	private updateDashboards(updatedDashboard: DashboardEntity) {
		const dashboardIndex = this.dashboards.findIndex(({ id }) => updatedDashboard.id === id);
		if (dashboardIndex === -1) {
			throw new Error("Dashboard not exist");
		}

		this.dashboards[dashboardIndex] = updatedDashboard;

		this.setDashboards([...this.dashboards]);
	}

	public async createDashboard() {
		await this.dashboardApi.createDashboard();
		await this.fetchDashboards();
	}

	public async duplicateDashboard(dashboardId: EntityId) {
		await this.dashboardApi.duplicateDashboard(dashboardId);
		await this.fetchDashboards();
	}

	public async deleteDashboard(dashboardId: EntityId) {
		await this.dashboardApi.deleteDashboard(dashboardId);
		await this.fetchDashboards();
	}

	public async renameDashboard(dashboardId: EntityId, dashboardName: string) {
		const dashboardExists = this.dashboards.some(({ id }) => dashboardId === id);
		if (!dashboardExists) {
			throw new Error("Dashboard not exist");
		}

		const updatedDashboard = await this.dashboardApi.renameDashboard(dashboardId, dashboardName);
		this.updateDashboards(updatedDashboard);
	}

	public getDashboards() {
		return this.dashboards;
	}

	public setDashboards(dashboards: DashboardEntity[]) {
		this.dashboards = dashboards;
		this.event.emit("changeDashboards", dashboards);
	}

	public getDefaultDashboard() {
		return this.defaultDashboard;
	}

	public setDefaultDashboard(dashboard: DashboardEntity | null) {
		this.defaultDashboard = dashboard;
		this.event.emit("changeDefaultDashboard", dashboard);
	}

	public getAtmoboxes() {
		return this.atmoboxes;
	}

	public setAtmoboxes(atmoboxes: Atmobox[]) {
		this.atmoboxes = atmoboxes;
		this.event.emit("changeAtmoboxes", atmoboxes);
	}

	public getAtmoboxesConfig() {
		return this.atmoboxesConfig;
	}

	public setAtmoboxesConfig(atmoboxesConfig: AtmoboxesConfig | null) {
		this.atmoboxesConfig = atmoboxesConfig;
		this.event.emit("changeAtmoboxesConfig", atmoboxesConfig);
	}

	public onChangeDashboards(callback: (dashboards: DashboardEntity[]) => void) {
		this.event.on("changeDashboards", callback);
		return () => {
			this.event.off("changeDashboards", callback);
		};
	}

	public onChangeDefaultDashboard(callback: (defaultDashboard: DashboardEntity | null) => void) {
		this.event.on("changeDefaultDashboard", callback);
		return () => {
			this.event.off("changeDefaultDashboard", callback);
		};
	}

	public onChangeAtmoboxes(callback: (atmoboxes: Atmobox[]) => void) {
		this.event.on("changeAtmoboxes", callback);
		return () => {
			this.event.off("changeAtmoboxes", callback);
		};
	}

	public onChangeAtmoboxesConfig(callback: (atmoboxesConfig: AtmoboxesConfig) => void) {
		this.event.on("changeAtmoboxesConfig", callback);
		return () => {
			this.event.off("changeAtmoboxesConfig", callback);
		};
	}

	public disconnect() {
		this.setDefaultDashboard(null);
		this.setDashboards([]);
		this.setAtmoboxes([]);
		this.setAtmoboxesConfig(null);
	}
}
